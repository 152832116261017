<template>
  <v-footer class="footer" color="footer_color">
    <div class="footer_wrapper">
      <v-row class="no-gutters">
        <v-col class="footer-container-1">

          <div class="payment-methods">
            <div class="footer-title mb-2">{{ $t(`footer.paymentMethods`) }}</div>
            <div class="d-flex flex-wrap payment-methods-icons">
              <template v-for="(paymentMethod, i) in paymentMethods">
                <img width="32" height="32" class="footer-icon" :key="i" :src="`/static/image/footer/${paymentMethod.img}.png`" alt
                      :contain="true" />
                <!-- <v-hover v-slot="{ hover }" class="mr-3" :key="paymentMethod">
                    <v-img max-width="67px" max-height="24px" :src="`/static/image/footer/${paymentMethod.img}.png`" alt>
                        <v-img v-if="hover" max-width="67px" max-height="24px" :src="`/static/image/footer/${paymentMethod.img}-color.png`" alt />
                    </v-img>
                </v-hover> -->
              </template>
            </div>
          </div>

<!--          <div class="d-flex flex-column customer-support">-->
<!--            <label class="footer-title">{{ $t(`footer.customerSupport`) }}</label>-->
<!--            <div class="d-flex">-->
<!--              <img width="32px" height="32px" src="/static/image/footer/icon-24hr.svg" alt="24hrs"/>-->
<!--              <div class="d-flex flex-column column-gap justify-center align-center">-->
<!--                <div class="footer-support secondary&#45;&#45;text"><a href="https://direct.lc.chat/15594630/" style="text-decoration: none" target="_blank">{{ $t(`footer.chatNow`) }}</a></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="customer-support">
            <div class="footer-title mb-2">{{ $t(`footer.responsiveGaming`) }}</div>
            <div class="d-flex flex-wrap payment-methods-icons">
              <template v-for="(responsbileGambling, i) in responsbileGamblingLink">
                <img width="32" height="32" :key="i" class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}.png`"
                     alt="responsible gaming" />
                <!-- <v-hover v-slot="{ hover }" class="mr-3" :key="responsbileGambling">
                    <v-img class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}.png`" alt>
                        <v-img v-if="hover" class="footer-icon" :src="`/static/image/footer/${responsbileGambling.img}-color.png`" alt />
                    </v-img>
                </v-hover> -->
              </template>
            </div>
          </div>
          <div class="community">
            <v-row no-gutters class="mb-2">
              <label class="footer-title">{{ $t(`footer.certification`) }}</label>
            </v-row>
            <v-row no-gutters class="d-flex flex-wrap payment-methods-icons">
              <img width="99" height="32" src="/static/image/footer/gaming_license.png" alt="gaming license"/>
              <div>
                <a href="https://casinosanalyzer.com/" target="_blank">
                  <img width="77" height="32" src="/static/image/footer/gaming_Casino_Analyzer.png" alt="casino analyzer"/>
                </a>
              </div>
              <img width="32" height="32" src="/static/image/footer/gaming_iTechLabs.png" alt="gaming itech" class="ml-2"/>
            </v-row>
          </div>

          <div class="followUs">
            <v-row no-gutters class="mb-2">
              <label class="footer-title">{{ $t(`footer.followUs`) }}</label>
            </v-row>
            <v-row no-gutters class="d-flex flex-wrap payment-methods-icons">
              <div>
                <a href="https://www.facebook.com/krikyaofficial" target="_blank">
                  <img width="32" height="32" src="/static/image/footer/fb.png" alt="krikya facebook"/>
                </a>
              </div>
              <div>
                <a href="https://t.me/krikya88" target="_blank">
                  <img width="32" height="32" src="/static/image/footer/telegram.png" alt="krikya telegram"/>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/krikyaofficial/" target="_blank">
                  <img width="32" height="32" src="/static/image/footer/ig.png" alt="krikya instagram"/>
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/@krikyaofficial" target="_blank">
                  <img width="32" height="32" src="/static/image/footer/Youtube.png" alt="krikya youtube"/>
                </a>
              </div>
              <div>
                <a href="https://twitter.com/KRIKYA_OFFICIAL" target="_blank">
                  <img width="32" height="32" src="/static/image/footer/Twitter.png" alt="krikya twitter"/>
                </a>
              </div>
            </v-row>
          </div>

        </v-col>
      </v-row>

      <!-- <v-col cols="12" class="py-5">
        <v-divider></v-divider>
      </v-col> 

      <v-row no-gutters class="text-left">
        <h1 style="font-size: 1.17em">{{ $t(`footer.trustedPremiumOnlineCasinoTitle`) }} </h1>
        <p id="aaa" class="seo_text">
          {{$t(`footer.trustedPremiumOnlineCasinoDesc_1`)}}<br/>
          {{$t(`footer.trustedPremiumOnlineCasinoDesc_2`)}}<br/>
        </p>
      </v-row> -->

      <footer-content></footer-content>
    
      <v-row>
        <v-col cols="12" class="my-2">
          <v-divider></v-divider>
        </v-col>
      </v-row>

        <v-row no-gutters justify="space-between" class="align-center" cols="12">
            <v-row no-gutters class="mb-2">
                <img width="120" height="30" style="align-self:end" class="footer-logo" src="/static/image/footer/logo_black.png" alt="krikya logo" />
                <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp" class="footer-divider ml-5"></v-divider>
            </v-row>
            <v-col no-gutters cols="12" :md="4" class="faq-container">
                <v-row class="faq-wrapper">
                    <router-link :to="{ name: routeName.APPLY_AGENT, params: { lang: this.$route.params.lang } }" class="footer-title">{{$t(`label.affiliate`)}}</router-link>
                    <router-link :to="{ name: routeName.FAQ, params: { lang: this.$route.params.lang } }" class="footer-title">{{$t(`label.responbileGaming`)}}</router-link>
                    <router-link :to="{ name: routeName.TNC, params: { lang: this.$route.params.lang } }" class="footer-title">{{$t(`label.tnc`)}}</router-link>
                    <router-link :to="{ name: routeName.ABOUT_US, params: { lang: this.$route.params.lang } }" class="footer-title">{{$t(`label.aboutUs`)}}</router-link>
                </v-row>
                <!-- <div v-show="$vuetify.breakpoint.smAndDown" class="title_color--text font-weight-bold">{{$t(`footer.bestQualityPlatform`)}}</div> -->
                <div class="copyright" style="color: var(--v-description-darken3)">{{ footerCopyRight }}</div>
            </v-col>
        </v-row>
    </div>
  </v-footer>
</template>

<script>
import FooterContent from '@/components/layout/FooterContent.vue'
import NavOnePay from '@/assets/svg/onePay.svg'
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import format from 'string-format'
import {
  locale,
  uiHelper
} from '@/util'
import {
  SHARED,
  CACHE_KEY
} from '@/constants/constants'
import {
  SHARED_LOADING
} from '@/store/shared.module'

export default {
  name: 'appFooter',
  props: {},
  components: {
    FooterContent,
    NavOnePay
  },
  data: () => ({
    isViewableAll: false,
    footerProvider: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    supportedLanguage: SHARED.SUPPORTED_LANGUAGES,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    routeName: ROUTE_NAME,
    footerLink: [
      // {
      //   link: '/customer-service',
      //   text: locale.getMessage(`label.customerROUTE_NAMEService`)
      // },
      {
        routeName: ROUTE_NAME.TNC,
        text_key: 'termsAndConditions'
      },
      {
        routeName: ROUTE_NAME.ABOUT_US,
        text_key: 'aboutUs'
      },
      {
        routeName: ROUTE_NAME.FAQ,
        text_key: 'faq'
      },
      {
        routeName: ROUTE_NAME.CONTACT_US,
        text_key: 'contactUs'
      }
    ],
    socialMediaLink: [{
      name: 'facebook',
    },
      {
        name: 'instagram',
      },
      {
        name: 'twitter',
      },
      {
        name: 'youtube',
      },
      {
        name: 'pinterest',
      },
    ],
    responsbileGamblingLink: [
      {
        img: 'safe2',
      },
      {
        img: 'safe1_2',
      }
    ],
    paymentMethods: [{
      img: 'payment-bank'
    }
    // ,
    //   {
    //     img: 'payment-bkash',
    //   },
    //   {
    //     img: 'payment-nagad',
    //   },
    //   {
    //     img: 'payment-rocket',
    //   }
    ],
    footerCopyRight: format(locale.getMessage(`message.copyRight`), new Date().getFullYear())
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    }
  },
  watch: {
    ProviderTypes() {
      this.footerProvider = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
      return this.$store.state.game.ProviderTypes
    }
  },
  methods: {
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED_LOADING}`)
      locale.setCurrentLanguage(language)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    goToFirstCagayan() {
      window.open('https://www.firstcagayan.com/zh-hant#2', '_blank')
    },
    goToPagcor() {
      window.open('http://www.pagcor.ph/index.php', '_blank')
    }
  }
}
</script>

<style lang="scss">
.v-footer {
  position: unset;

  .footer {
    //min-height: 1080px;
    padding-bottom: 12px;
  }

  .footer-title {
    font-size: 12px;
    font-family: "ReadexPro-Bold";
  }

  .footer_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 30px auto;
    font-size: 12px;

    .footer-container-1 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 20px;

      .column-gap {
        gap: 4px;
      }
    }
  }

  .footer-divider {
    color: var(--v-divider-base);
  }

  .footer-logo {
    width: 120px;
  }

  .footer-disabled {
    filter: grayscale(100%);
  }

  .footer-icon {
    max-width: 32px;
    max-height: 32px;
  }

  .seo_text {
    font-size: 14px;
    color: var(--v-description-darken3);
  }

  .footer-link-divider {
    border-color: #3A3A3A;
  }

  .footer-support:hover {
    color: var(--v-primary-base);
  }

  .gaming-license {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;

  }

  .payment-methods-icons {
    gap: 12px;
  }

    .faq-container {
        text-align: end;

        .faq-wrapper {
            gap: 12px;
            color: var(--v-primary-base);
            margin: 0px 0px 12px 0px;
            justify-content: end;
        }
    }


}

@media (max-width: 599px) {
  .mobile-gaming-license{
    //width: calc(45% - 10px);
    width: 50%;
  }
  .footer {
    margin-bottom: 50px;

    .footer_wrapper {

      .footer-container-1 {

        .customer-support {
          width: 50%;
        }

        .community {
          //width: calc(45% - 10px);
          width: 100%;
        }

        .followUs {
          //width: calc(45% - 10px);
          width: 100%;
        }

        .payment-methods {
          //width: calc(45% - 10px);
          width: 50%;
        }

                .payment-methods-icons {
                    gap: 20px;
                }
            }

            .faq-container {
                text-align: start;

                .faq-wrapper {
                    justify-content: start;
                }
            }
        }
    }
}
</style>
