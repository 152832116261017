<template>
    <v-row no-gutters>
        <v-row v-if="[ routeName.VIP, routeName.PROMOTION ].includes($route.name)" style="width:100%">
            <v-col cols="12" class="py-5">
                <v-divider></v-divider>
            </v-col>
        </v-row>
        
<!--        <v-row v-if="$route.name == routeName.HOME">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Krikya | Online Casino {{ currentYear }} | IPL | Indian Premier League | Crazy Time | Krikya88 | Krikya Trusted Online Betting Site | Online Casino Bangladesh | Krikya Bet | Krikya Casino App Download</h1>-->
<!--                <br/>-->
<!--                <h2>Begin Playing {{ currentYear }}'s Top Online Casino Bangladesh</h2>-->
<!--                <p>With krikya casino {{ currentYear }}, you can play Live Casino Bangladesh games, play slot games, bet on cricket, and win real money or jackpots. With a wide range of slot games and Online Casino Bangladesh, we can provide you with the best of what is available.</p>-->
<!--                <p>Online Slot Bangladesh, Indian Premier League, live ball, mad time, and betting are a few of the many types of Cricket Exchange games we offer.</p>-->
<!--                <h2>Top Games Pick At krikya.group</h2>-->
<!--                <p>In addition to sports and top online casino {{ currentYear }}, we also offer slots, arcade games, and live ball games. This will keep you entertained and provide you with better opportunities to win amazing prizes and real money. Furthermore, the Bangladeshi online Krikya casino offers you the chance to try your luck in Online Casino Bangladesh.</p>-->
<!--                <h2>Play Top Cricket Casino in Bangladesh</h2>-->
<!--                <p>There is a variety of cricket casino {{ currentYear }} in Bangladesh gaming options available to you. At the top cricket betting site Bangladesh, we have Bangladesh Cricket Betting, Cricket Exchange, and have fun. In addition, you can choose from several cricket betting options that will provide you with an entirely different gaming experience. So, go to best cricket betting,download it and start playing playing Best Cricket Betting!</p>-->
<!--                <p>Join us for Best cricket betting!</p>-->
<!--                <h2>Create Account To Play krikya.group Games</h2>-->
<!--                <p>Creating an account is much easier and takes hardly any time for Crazy Time experience. Let our experts handle the rest of the work after you have read through the Indian Premier League details and reviewed and accepted all terms and conditions.</p>-->
<!--                <p>Whether you as a casino player are interested in learning the basics of IPL or enjoying online slots bangladesh Bangladesh, we provide you with the right solutions on Krikya bet.</p>-->
<!--                <h2>Is krikya.group casino safe to play?</h2>-->
<!--                <p>Absolutely yes! krikya.group is safe to play the verities of online casino games and betting. You can come to us and play or bet on any of your favorite casino games today!</p>-->
<!--                <h2>How to join krikya.group?</h2>-->
<!--                <p>Visit krikya.group and sign up to join our casino. The time you join the casino, you will get exciting gaming offers and also big rewards to win.</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2">ক্রিক্য | অনলাইন ক্যাসিনো {{ currentYear }} | হিন্দুন প্রিমিয়ার লিগ | পাগলামী সময় | আইপিএল | ইন্ডিয়ানপ্রিমিয়ার লিগ | পাগল সময় | Krikya88 | ক্রিক্যা বিশ্বস্ত অনলাইন বেটিং সাইট</h1>-->
<!--                <br/>-->
<!--                <h2>{{ currentYear }} এর সেরা অনলাইন ক্যাসিনো বাংলাদেশ খেলা শুরু করুন</h2>-->
<!--                <p> সঙ্গে krikya casino {{ currentYear }}, আপনি লাইভ ক্যাসিনো বাংলাদেশ গেম খেলতে পারেন, স্লট গেম খেলতে পারেন, ক্রিকেটে বাজি ধরতে পারেন এবং আসল টাকা বা জ্যাকপট জিততে পারেন। স্লট গেমের বিস্তৃত পরিসর এবং অনলাইন ক্যাসিনো বাংলাদেশ এর সাথে, আমরা আপনাকে যা পাওয়া যায় তার সেরাটি দিতে পারি।</p >-->
<!--                <p>অনলাইন স্লট বাংলাদেশ, ইন্ডিয়ান প্রিমিয়ার লিগ, লাইভ বল, পাগলামী সময়, এবং বেটিং হল এর অনেক প্রকারের কয়েকটি। ক্রিকেট এক্সচেঞ্জ গেম আমরা অফার করি।</p>-->
<!--                <h2>krikya.group-এ সেরা গেম বাছাই করুন</h2>-->
<!--                <p>খেলাধুলা এবং অনলাইন ক্যাসিনো {{ currentYear }} ছাড়াও, আমরা স্লট, আর্কেড গেম এবং লাইভ বল গেমও অফার করি। এটি আপনাকে বিনোদন দেবে এবং আপনাকে আশ্চর্যজনক পুরস্কার এবং আসল অর্থ জেতার আরও ভাল সুযোগ প্রদান করবে। উপরন্তু, বাংলাদেশী অনলাইন Krikya ক্যাসিনো আপনাকে অনলাইন ক্যাসিনো বাংলাদেশ।</p>-->
<!--                <h2>বাংলাদেশে শীর্ষ ক্রিকেট ক্যাসিনো খেলুন</h2>-->
<!--                <p>বাংলাদেশে বিভিন্ন ধরনের ক্রিকেট ক্যাসিনো গেমিং অপশন আপনার জন্য উপলব্ধ। শীর্ষে ক্রিকেট বেটিং বাংলাদেশ, আমাদের আছে বাংলাদেশ ক্রিকেট বাজি, ক্রিকেট এক্সচেঞ্জ, এবং মজা করুন৷ এছাড়াও, আপনি বিভিন্ন ক্রিকেট বেটিং অপশন থেকে বেছে নিতে পারেন যা আপনাকে সম্পূর্ণ ভিন্ন গেমিং অভিজ্ঞতা প্রদান করবে। সুতরাং, সেরা ক্রিকেট বেটিং এ যান, ডাউনলোড করুন এবং সেরা ক্রিকেট খেলা শুরু করুন!</p>-->
<!--                <p> সেরা ক্রিকেট খেলার জন্য আমাদের সাথে যোগ দিন!</p>-->
<!--                <h2>krikya.group গেম খেলতে অ্যাকাউন্ট তৈরি করুন</h2>-->
<!--                <p>একটি অ্যাকাউন্ট তৈরি করা অনেক সহজ এবং ক্রেজি টাইম অভিজ্ঞতার জন্য খুব কমই সময় লাগে৷ আপনি ইন্ডিয়ান প্রিমিয়ার লিগের বিশদটি পড়ার পরে এবং সমস্ত শর্তাবলী পর্যালোচনা ও গ্রহণ করার পরে আমাদের বিশেষজ্ঞদের বাকি কাজ পরিচালনা করতে দিন .</p>-->
<!--                <p>আপনি একজন ক্যাসিনো খেলোয়াড় হিসেবে IPL এর মূল বিষয়গুলি শিখতে বা উপভোগ করতে আগ্রহী কিনা অনলাইন স্লট বাংলাদেশ বাংলাদেশ, আমরা আপনাকে ক্রিক্যা বেটের সঠিক সমাধান প্রদান করি।</p>-->
<!--                <h2>krikya.group ক্যাসিনো খেলার জন্য নিরাপদ?</h2>-->
<!--                <p>অবশ্যই হ্যাঁ! krikya.group অনলাইন ক্যাসিনো গেম এবং বাজি খেলার জন্য নিরাপদ। আপনি আমাদের কাছে এসে আজই আপনার পছন্দের যে কোনো ক্যাসিনো গেম খেলতে বা বাজি ধরতে পারেন!</p>-->
<!--                <h2>কিভাবে krikya.group এ যোগদান করবেন?</h2>-->
<!--                <p>krikya.group-এ যান এবং আমাদের ক্যাসিনোতে যোগ দিতে সাইন আপ করুন। আপনি ক্যাসিনোতে যোগদান করার সময়, আপনি আকর্ষণীয় গেমিং অফার পাবেন এবং জেতার জন্য বড় পুরস্কারও পাবেন।</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-row v-else-if="$route.name == routeName.CASINO">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Online Casino Bangladesh | Live Casino Bangladesh {{ currentYear }}</h1>-->
<!--                <br/>-->
<!--                <v-expansion-panels accordion v-model="panel">-->
<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header >I. Introduction</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                      <p>-->
<!--                        A.	Definition of Casino Betting<br>-->
<!--                        Casino betting refers to the act of wagering money or something of value on games of chance or skill at a casino. The outcome of the bet is determined by the randomness of the game or the skill of the participant. There are various forms of casino betting such as slot machines, table games, sports betting, and poker. Casinos often offer incentives such as bonuses or rewards to attract and retain players. Betting at a casino is a form of entertainment and should be approached responsibly. Gambling addiction is a real problem and individuals should always set limits and practice responsible gambling behavior.-->
<!--                      </p>-->
<!--                      <p>B.	Overview of Casino Betting in Bangladesh<br>-->
<!--                        Casino betting is illegal in Bangladesh and the government strictly prohibits it. However, underground illegal casinos continue to operate in the country, particularly in the capital city of Dhaka. The lack of regulation in these underground establishments raises concerns over the fairness of games and the safety of players. Despite government efforts to crack down on illegal gambling, the demand for casino betting remains high in Bangladesh. Nevertheless, it is important for individuals to be aware of the legal consequences of participating in illegal casino betting and to choose safer alternatives for their entertainment and leisure activities.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header >II. Understanding the Legal Landscape of Casino Betting in Bangladesh</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content >-->
<!--                      <p>-->
<!--                        There is no Online Casino Bangladesh, and the government strictly prohibits it. It is still possible to find underground illegal casinos throughout the country despite government efforts. Laws governing these activities need to be understood and abided by by individuals who participate in them.-->
<!--                      </p>-->
<!--                      <p>-->
<!--                        A.	Overview of Bangladesh Gambling Laws<br>-->
<!--                        A majority of forms of betting in Bangladesh are illegal due to strict gambling laws. Sports betting, casino betting, and online gambling are all strictly prohibited by the government. The existence of underground illegal casinos in Bangladesh continues despite these laws. Those who engage in illegal gambling activities may be penalized by law, including imprisonment and fines. To prevent illegal gambling from spreading throughout the country and to protect citizens from gambling addiction, the government enforces these laws. Gambling laws in the country should be understood and abided by, and safer alternatives to gambling should be sought.-->
<!--                      </p>-->
<!--                      <p>B.	Legal Status of Casino Betting in Bangladesh<br>-->
<!--                        There is zero tolerance for gambling in the country, including Online Casino Bangladesh betting, online gambling, and sports betting. Underground casinos are regularly raided by the government, which regularly cracks down on illegal gambling operations. A person who engages in illegal casino betting may face fines and imprisonment as a result of their actions. The country's laws must be understood and abided by individuals, and safer alternatives should be sought for leisure and entertainment.-->
<!--                      </p>-->
<!--                      <p>-->
<!--                        C.	Consequences of Illegal Casino Betting in Bangladesh-->
<!--                        Legal Penalties:<br>-->
<!--                        If you are caught gambling illegally in a casino in Bangladesh, you may face fines and imprisonment. Gambling is strictly prohibited by the government, including casino betting.-->
<!--                        Lack of Regulation:<br>-->
<!--                        The safety of players and the fairness of games are often at risk in illegal underground casinos in Bangladesh due to a lack of adequate regulation and oversight.<br>-->
<!--                        Criminal Activities:<br>-->
<!--                        Criminal activities, such as money laundering, are also easier to commit in illegal casinos due to the lack of regulation.<br>-->
<!--                        Risks to Individuals:<br>-->
<!--                        It can also lead to financial losses and gambling addiction as a result of illegal casino betting. Gambling laws in the country must be understood and adhered to, and there should be a focus on finding safer alternatives.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->

<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header>III. Popular Forms of Casino Betting in Bangladesh</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        A.	Overview of Popular Casino Games in Bangladesh<br>-->

<!--                        There is a wide variety of Online Casino Bangladesh games available at online casinos in Bangladesh. This ensures that casino players never run out of games at these online casinos. Therefore, players will find different game portfolios as the games offered come in different shapes and forms.<br>-->
<!--                        1.Slots-->
<!--                        We will begin by discussing slots. All casino games are popular, but slots are arguably the most popular. It is largely due to the simplicity of slots that they are so popular. Due to their simplicity, slots can be enjoyed by players of all levels, whether they are beginners or professionals.<br>-->
<!--                        2.Table and Card Games<br>-->
<!--                        There is no doubt that the table and card games portfolio is one of the most popular casino games portfolios. A table game and a card game are both games that are played on a table and/or use playing cards.<br>-->
<!--                        3. Poker<br>-->
<!--                        Due to the fact that poker is played with cards, it is technically a card game. Poker, however, is generally classified as another gambling category by most online casinos. It is the general objective of playing poker to build a hand (set of cards) that ranks higher than other competing hands.<br>-->
<!--                        4. Specialty Games<br>-->
<!--                        It's possible for players to find other casino games inside Bangladeshi online casinos that don't fall into one of the above game categories. Specialty games will encompass such games. This portfolio contains a wide selection of casino games.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        B. Comparison of Land-Based and Online Casinos in Bangladesh<br>-->

<!--                        Land-based casinos are physical establishments where patrons can play a variety of casino games, such as slot machines, table games, and poker. Online casinos, on the other hand, are virtual establishments that offer the same games but through the internet.<br>-->
<!--                        When it comes to the comparison of land-based and online casinos, there are several key differences:<br>-->
<!--                        1.	Convenience: Online casinos are more convenient as casino games can be accessed from the comfort of one's home or anywhere with an internet connection. Land-based casinos require physical travel and may have limited operating hours.<br>-->
<!--                        2.	Game Selection: Online casinos typically offer a wider range of games than land-based casinos.<br>-->
<!--                        3.	Bonuses and Promotions: Online casino offer the top gaming bonuses and promotions to attract and retain players.<br>-->
<!--                        4.	Social Interaction: Land-based casinos offer a social atmosphere where players can interact with each other and the dealers. Online casinos offer a more solitary experience in Live Casino Bangladesh.<br>-->
<!--                        It's important to note that gambling laws and casino regulations vary from country to country, and in some countries, like Bangladesh, both land-based and online casinos are illegal. It's important to understand and abide by the local laws and regulations.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                     <p>-->
<!--                       C. Advantages and Disadvantages of Online Casino Betting<br>-->

<!--                       Advantages of Online Casino Betting:<br>-->
<!--                       1.	Convenience: Online casinos can be accessed from anywhere with an internet connection, making it easier for players to enjoy their favorite games from the comfort of their own homes.<br>-->
<!--                       2.	Game Selection: Online casinos typically offer a wider range of Live Casino Bangladesh games, including both traditional and modern games, than land-based casinos.<br>-->
<!--                       3.	Bonuses and Promotions: Online casinos often offer bonuses, such as welcome bonuses and free spins, to attract and retain players.<br>-->
<!--                       4.	Anonymity: Online casino betting allows players to remain anonymous, which can be beneficial for players who prefer to keep casino gambling activities private.-->

<!--                       Disadvantages of Online Casino Betting:<br>-->
<!--                       1.	Security Risks: Online casinos can be vulnerable to hacking and other security threats, which can put players' personal and financial information at risk.<br>-->
<!--                       2.	Lack of Regulation: Many online casinos lack proper regulation and oversight, which can result in unethical practices, such as rigged games and slow payouts.<br>-->
<!--                       3.	Addiction Risks: The easy access and convenience of online casino betting can increase the risk of gambling addiction.<br>-->
<!--                       4.	Limited Interaction: Online casinos offer a more solitary experience, which can be less engaging and enjoyable than the social atmosphere of land-based casinos.-->
<!--                     </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header>IV. Strategies for Successful Casino Betting in Bangladesh</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        A.	Importance of Bankroll Management:<br>-->
<!--                        Bankroll management is important in casino betting because it helps players to control their spending, stay within their means, and minimize losses. By managing their bankroll effectively, players can ensure that they have enough funds to continue playing their favorite games, while also avoiding financial problems or debt.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        B. Understanding the Odds and House Edge<br>-->
<!--                        Odds and house edge are important concepts in casino betting as they determine the likelihood of winning and the profitability of the game for the casino.<br>-->
<!--                        Odds refer to the ratio of the likelihood of an event happening to the likelihood of it not happening. In casino betting, odds determine the payouts for a winning bet.<br>-->
<!--                        Example: If the odds of winning a bet are 2:1, then for every 1 unit bet, the payout will be 2 units if the bet wins.-->
<!--                        House Edge refers to the advantage that the casino has over the player. It is then calculated as the difference between the true odds of a game and the odds that the player receives. For example, if the true odds of winning a certain game are 50%, but the payouts are set at 1:1, the house edge is 50% - 100% = -50%. This means that for every 100 units bet, the casino will make a profit of 50 units, on average.<br>-->
<!--                        Understanding the odds and house edge is important for players as it helps them to choose games that offer the best chances of winning, while also minimizing their losses. Players should always look for games with a lower house edge and favorable odds, and they should also set limits on their spending and manage their bankroll effectively.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        C.	Choosing Games with a Low House Edge<br>-->
<!--                        Choosing games with a low house edge is one way to improve your chances of winning at the casino. The lower the casino gaming house edge, the better will be the odds for the player. Here are a few popular casino games with a low house edge:<br>-->
<!--                        1.	Blackjack: With proper strategy, the house edge in blackjack can be as low as 0.5%.<br>-->
<!--                        2.	Baccarat: Baccarat has a low house edge of 1.06% when betting on the banker and 1.24% when betting on the player.<br>-->
<!--                        3.	Craps: The house edge in craps can vary greatly depending on the bet, but it can be as low as 1.41% when betting on the pass line.<br>-->
<!--                        4.	Video Poker: Video poker machines that offer a full paytable can have a house edge as low as 0.46%.<br>-->
<!--                        5.	Roulette: The European version of roulette has a lower house edge of 2.7% compared to the American version, which has a house edge of 5.26%.<br>-->
<!--                        Players should also be aware of the rules and variations of each game, as these can greatly affect the house edge. For example, some blackjack games may have rules that increase the house edge, such as a 6:5 payout for blackjack instead of the standard 3:2.<br>-->
<!--                        It's important to remember that even with a low house edge, there is still a built-in advantage for the casino, and the outcome of each game is ultimately determined by chance. Players should manage their bankroll effectively and avoid chasing losses.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header>V. Common Mistakes to Avoid When Betting at Casinos in Bangladesh</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        A. Chasing Losses<br>-->
<!--                        Chasing losses is a dangerous behavior in casino betting, as it involves continuing to place bets in an attempt to recoup previous losses. This often leads to larger losses, as players become more and more invested in trying to win back their money.<br>-->
<!--                        Here are a few reasons why chasing losses is harmful in casino betting:<br>-->
<!--                        1.	Emotional Involvement: Chasing losses often involves becoming emotionally invested in the outcome of the game, which can cloud judgement and lead to poor decision-making.<br>-->
<!--                        2.	Increased Spending: By continuing to place bets, players increase their spending and the amount of money they stand to lose.<br>-->
<!--                        3.	Reduced Chances of Winning: The longer a player continues to chase losses, the less likely they are to win, as the odds of winning are determined by chance and are not influenced by past results.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        B.	Drinking Alcohol While Betting<br>-->
<!--                        Drinking alcohol while betting can be harmful for several reasons:<br>-->
<!--                        1.	Impairs Judgment: Alcohol impairs judgement, which can lead to poor decision-making and increased risk-taking behavior in betting. This can result in larger losses and reduced chances of winning.<br>-->
<!--                        2.	Clouds Perception: Alcohol can also cloud perception, leading to misunderstandings of odds and house edge, as well as incorrect estimations of the likelihood of winning.<br>-->
<!--                        3.	Decreased Self-Control: Alcohol decreases self-control, which can lead to overspending and chasing losses, even when the player knows they should stop.<br>-->
<!--                        4.	Increased Risk of Addiction: Drinking while betting can lead to risk of developing a gambling addiction, as the combination of alcohol and betting can create a powerful emotional experience that is difficult to resist.-->
<!--                        Overall, drinking alcohol while betting can have serious consequences for both the player's health and their finances. It is best to avoid drinking while betting or to limit alcohol consumption to avoid these harmful effects.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        C. Not Setting Limits and Sticking to Them<br>-->
<!--                        Not setting limits and sticking to them while betting in a casino can be harmful in several ways:-->
<!--                        1.	Overspending: Without limits, it's easy to overspend and lose more money than you can afford, leading to financial stress and potentially even financial ruin.-->
<!--                        2.	Chasing Losses: Without limits, it's also easy to chase losses, which is a dangerous and ineffective way to try to win back lost money.-->
<!--                        3.	Reduced Enjoyment: Without limits, betting can become less enjoyable, as the focus shifts from having fun to winning back lost money.-->
<!--                        4.	Addiction: Not setting limits can also increase the risk of developing a gambling addiction, as players may feel the need to continue playing.-->
<!--                        It's important for players to set limits before they begin betting and to stick to them, as this will help them avoid overspending, chasing losses, and potentially harmful behavior. Limits can be set in terms of time or money, and should be realistic and reflective of the player's financial situation and personal values.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel class="footer-expansion-panel">-->
<!--                    <v-expansion-panel-header>VI. Conclusion</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <p>-->
<!--                        A.	Recap of Key Points<br>-->
<!--                        1.	Illegal in Bangladesh: Casino betting is illegal in Bangladesh, and those who participate in it face the risk of fines and imprisonment.<br>-->
<!--                        2.	Understanding odds and house edge: It is important for players to understand the odds and house edge of the games they play in order to make informed decisions and maximize their chances of winning.<br>-->
<!--                        3.	Responsible betting habits: Betting responsibly, such as setting and sticking to limits, avoiding chasing losses, and avoiding alcohol while betting, helps to prevent overspending, addiction, and other harmful behaviors.<br>-->
<!--                        4.	Comparison of land-based and online casinos: Both land-based and online casinos have their own unique advantages and disadvantages, and it is important for players to understand and compare these before making a decision on which type of casino to play in.-->
<!--                      </p>-->
<!--                      <p>B. Final Thoughts on Casino Betting in Bangladesh<br>-->
<!--                        In conclusion, casino betting is a popular form of gambling in Bangladesh, despite the fact that it is illegal. However, players should be aware of the consequences of participating in illegal betting, which can include fines and imprisonment.-->
<!--                        For those who choose to participate in casino betting, it is important to understand the odds and house edge of the games, as well as to practice responsible betting habits, such as setting and sticking to limits, avoiding chasing losses, and avoiding drinking while betting.-->
<!--                        It is also important to compare the advantages and disadvantages of both land-based and online casinos, as each has its own unique benefits and drawbacks.-->
<!--                        Overall, while casino betting can be a fun and exciting experience, it is important to approach it with caution and to practice responsible habits to ensure a safe and enjoyable experience.-->
<!--                      </p>-->
<!--                      <p>C. Encouragement to Bet Responsibly and Have Fun.<br>-->
<!--                        Responsible betting is important for a fun experience as it helps to ensure that the focus stays on enjoying the experience, rather than on losing control and losing more money than you can afford. By setting and sticking to limits, avoiding chasing losses, and avoiding alcohol while betting, players can reduce stress and increase enjoyment, making betting a fun and exciting experience.-->
<!--                      </p>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                </v-expansion-panels>-->

<!--&lt;!&ndash;                <h2>Join krikya.group for Online Casino in Bangladesh</h2>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>It is krikya.group that will provide you with the best Online Casino Bangladesh games. All our players will surely have a great time on our gaming platform!</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>If you have ever wanted to enjoy Live Casino Bangladesh or the real fun of cricket through the Indian Premier League, you have come to the right place - Krikya - a one-stop trusted name in this domain.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <h3>krikya.group: Live casino features</h3>&ndash;&gt;-->
<!--&lt;!&ndash;                <ol>&ndash;&gt;-->
<!--&lt;!&ndash;                <li>Krikya live casino games are also being introduced in Bangladesh</li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li>The table games will be available to new members</li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li>We offer daily cash drops in weekly tournaments at Daily Wins Slot and Live Casino Bangladesh</li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li>Get a chance to win by spinning a slot wheel</li>&ndash;&gt;-->
<!--&lt;!&ndash;                </ol>&ndash;&gt;-->
<!--&lt;!&ndash;                <h3>krikya.group: Exciting Mobile Slots Games</h3>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>We specialize in krikya.group mobile slots adventures at the safe mobile casino - one of our best features! It is guaranteed that all our players will find something new at krikya.group in Bangladesh since all our games are 100% exclusive.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                <h3>krikya.group: Big Betting games</h3>&ndash;&gt;-->
<!--&lt;!&ndash;                <p>With several Betting games packed with great rewards, and some innovative new features, we have everything you're looking for if you love the classic betting experience. Playing our online Betting games is like taking a trip back in time or entering a fantasy world full of treasures.</p>&ndash;&gt;-->

<!--&lt;!&ndash;                <p>Join us today! <p>Online Casino Bangladesh |&ndash;&gt;-->
<!--&lt;!&ndash;                Cricket Casino | Online Slots Bangladesh</p>&ndash;&gt;-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--              <v-expansion-panels accordion v-model="panel">-->
<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >I. ভূমিকা</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <p>ক্যাসিনো বেটিং এর সংজ্ঞা <br>-->
<!--                      ক্যাসিনো বাজি বলতে একটি ক্যাসিনোতে সুযোগ বা দক্ষতার খেলায় অর্থ বা মূল্যবান কিছু বাজি রাখার কাজকে বোঝায়। বাজির ফলাফল গেমের এলোমেলোতা বা অংশগ্রহণকারীর দক্ষতা দ্বারা নির্ধারিত হয়। ক্যাসিনো বাজির বিভিন্ন রূপ রয়েছে যেমন স্লট মেশিন, টেবিল গেম, স্পোর্টস বেটিং এবং জুজু। ক্যাসিনো প্রায়শই খেলোয়াড়দের আকৃষ্ট করতে এবং ধরে রাখার জন্য বোনাস বা পুরস্কারের মতো প্রণোদনা দেয়। একটি ক্যাসিনোতে বাজি ধরা একটি বিনোদন এবং দায়িত্বের সাথে যোগাযোগ করা উচিত। জুয়ার আসক্তি একটি বাস্তব সমস্যা এবং ব্যক্তিদের সর্বদা সীমা নির্ধারণ করা উচিত এবং দায়িত্বশীল জুয়ার আচরণ অনুশীলন করা উচিত।</p>-->
<!--                    <p>বি. বাংলাদেশে ক্যাসিনো বাজির ওভারভিউ <br>-->
<!--                      ক্যাসিনো বেটিং বাংলাদেশে অবৈধ এবং সরকার এটি কঠোরভাবে নিষিদ্ধ করে। তবে দেশে, বিশেষ করে রাজধানী ঢাকায় আন্ডারগ্রাউন্ড অবৈধ ক্যাসিনো চলছে। এই ভূগর্ভস্থ স্থাপনায় নিয়ন্ত্রণের অভাব গেমের ন্যায্যতা এবং খেলোয়াড়দের নিরাপত্তা নিয়ে উদ্বেগ বাড়ায়। অবৈধ জুয়া দমনে সরকারি প্রচেষ্টা সত্ত্বেও বাংলাদেশে ক্যাসিনো বাজির চাহিদা বেশি। তা সত্ত্বেও, ব্যক্তিদের অবৈধ ক্যাসিনো বাজিতে অংশগ্রহণের আইনি পরিণতি সম্পর্কে সচেতন হওয়া এবং তাদের বিনোদন ও অবসর ক্রিয়াকলাপের জন্য নিরাপদ বিকল্প বেছে নেওয়া গুরুত্বপূর্ণ৷ <br>-->
<!--                      ><strong>লাইভ ক্যাসিনো বাংলাদেশ</strong>-এ জুয়া খেলা লাভজনক হতে পারে যদি আপনি জানেন যে আপনি যদি ধারাবাহিকভাবে লাভ করতে চান তাহলে কোথায় দেখতে হবে। এই নিবন্ধটি জুড়ে, আমরা জনপ্রিয় ক্যাসিনো বেটিং, নিয়ম ও প্রবিধান, কৌশল এবং ভুল সহ ক্যাসিনো বেটিং সম্পর্কে জানার জন্য যা কিছু আছে তা কভার করব।-->
<!--                    </p>-->
<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->
<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >II. বাংলাদেশে ক্যাসিনো বেটিং এর আইনি ল্যান্ডস্কেপ বোঝা</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <p>বাংলাদেশে কোনো অনলাইন ক্যাসিনো নেই, এবং সরকার এটি কঠোরভাবে নিষিদ্ধ করে। সরকারি প্রচেষ্টা সত্ত্বেও সারা দেশে ভূগর্ভস্থ অবৈধ ক্যাসিনো খুঁজে পাওয়া সম্ভব। এই ক্রিয়াকলাপগুলিকে নিয়ন্ত্রিত আইনগুলি বুঝতে হবে এবং সেগুলিতে অংশগ্রহণকারী ব্যক্তিদের দ্বারা মেনে চলতে হবে৷ <br>-->
<!--                      ক. বাংলাদেশের জুয়া আইনের সংক্ষিপ্ত বিবরণ <br>-->
<!--                      কঠোর জুয়া আইনের কারণে বাংলাদেশে বাজির বেশিরভাগ ধরনই অবৈধ। স্পোর্টস বেটিং, ক্যাসিনো বাজি, এবং অনলাইন জুয়া সবই সরকার কর্তৃক কঠোরভাবে নিষিদ্ধ৷ এসব আইন থাকা সত্ত্বেও বাংলাদেশে ভূগর্ভস্থ অবৈধ ক্যাসিনোগুলোর অস্তিত্ব অব্যাহত রয়েছে। যারা বেআইনি জুয়া কার্যক্রমে জড়িত তাদের কারাদণ্ড এবং জরিমানা সহ আইন দ্বারা দণ্ডিত হতে পারে। সারা দেশে অবৈধ জুয়ার বিস্তার রোধ করতে এবং জুয়ার আসক্তি থেকে নাগরিকদের রক্ষা করার জন্য, সরকার এই আইনগুলি প্রয়োগ করে। দেশের জুয়া আইনগুলি বোঝা উচিত এবং মেনে চলা উচিত এবং জুয়ার নিরাপদ বিকল্পগুলি সন্ধান করা উচিত৷ <br>-->
<!--                      বি. বাংলাদেশে ক্যাসিনো বাজির আইনি অবস্থা <br>-->
<!--                      অনলাইন ক্যাসিনো বাংলাদেশ বেটিং, অনলাইন জুয়া এবং স্পোর্টস বেটিং সহ দেশে জুয়ার প্রতি শূন্য সহনশীলতা রয়েছে। আন্ডারগ্রাউন্ড <strong>লাইভ ক্যাসিনো 2023</strong>-এ সরকার নিয়মিত অভিযান চালায়, যা নিয়মিতভাবে অবৈধ জুয়া খেলার বিরুদ্ধে ক্র্যাক ডাউন করে। যে ব্যক্তি বেআইনি ক্যাসিনো বাজিতে লিপ্ত হয় তাদের কর্মের ফলে জরিমানা এবং কারাদণ্ড হতে পারে। দেশের আইনগুলি অবশ্যই ব্যক্তিদের দ্বারা বোঝা এবং মেনে চলতে হবে এবং অবসর ও বিনোদনের জন্য নিরাপদ বিকল্পগুলি সন্ধান করা উচিত। <br>-->
<!--                      গ. বাংলাদেশে অবৈধ ক্যাসিনো বাজির পরিণতি<br>-->
<!--                      ><strong>আইনি শাস্তি: </strong><br>-->
<!--                      <p>আপনি যদি বাংলাদেশের কোনো ক্যাসিনোতে বেআইনিভাবে জুয়া খেলতে ধরা পড়েন, তাহলে আপনাকে জরিমানা এবং কারাদণ্ড হতে পারে। ক্যাসিনো বাজি সহ সরকার কর্তৃক জুয়া কঠোরভাবে নিষিদ্ধ৷ </p>-->
<!--                      <p><strong>নিয়ন্ত্রণের অভাব: </strong></p>-->
<!--                      <p>পর্যাপ্ত নিয়ন্ত্রণ ও তদারকির অভাবে বাংলাদেশের অবৈধ আন্ডারগ্রাউন্ড ক্যাসিনোতে খেলোয়াড়দের নিরাপত্তা এবং খেলার ন্যায্যতা প্রায়ই ঝুঁকির মধ্যে থাকে। </p>-->
<!--                      <p><strong>অপরাধমূলক কার্যকলাপ: </strong> </p>-->
<!--                      <p>অপরাধমূলক কার্যকলাপ, যেমন মানি লন্ডারিং, নিয়ন্ত্রণের অভাবে অবৈধ ক্যাসিনোতে করাও সহজ। </p>-->
<!--                      <p><strong>ব্যক্তিদের জন্য ঝুঁকি: </strong> </p>-->
<!--                      <p>অবৈধ ক্যাসিনো বাজির ফলে আর্থিক ক্ষতি এবং জুয়ার আসক্তিও হতে পারে। দেশের জুয়া আইনগুলি অবশ্যই বোঝা এবং মেনে চলতে হবে এবং নিরাপদ বিকল্পগুলি খোঁজার দিকে মনোযোগ দেওয়া উচিত৷-->
<!--                    </p>-->
<!--                    <p></p>-->
<!--                    <p></p>-->
<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->
<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >III. বাংলাদেশে ক্যাসিনো বাজির জনপ্রিয় ফর্মগুলি</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <p>ক. বাংলাদেশের জনপ্রিয় ক্যাসিনো গেমের ওভারভিউ</p><br>-->
<!--                    <p>বাংলাদেশে <strong>সেরা অনলাইন লাইভ ক্যাসিনো</strong>-এ বিভিন্ন ধরনের অনলাইন ক্যাসিনো বাংলাদেশ গেম পাওয়া যায়। এটি নিশ্চিত করে যে এই অনলাইন ক্যাসিনোগুলিতে ক্যাসিনো খেলোয়াড়দের কখনই গেম ফুরিয়ে যায় না। তাই, প্লেয়াররা বিভিন্ন গেমের পোর্টফোলিও খুঁজে পাবে কারণ অফার করা গেমগুলি বিভিন্ন আকার এবং আকারে আসে। </p>-->
<!--                    <ol>-->
<!--                      <li><strong>স্লট</strong></li>৷-->
<!--                      <p>আমরা স্লট নিয়ে আলোচনা করে শুরু করব। সব ক্যাসিনো গেম জনপ্রিয়, কিন্তু স্লট তর্কযোগ্যভাবে সবচেয়ে জনপ্রিয়। এটি মূলত সরলতার কারণেস্লট যে তারা এত জনপ্রিয়. তাদের সরলতার কারণে, স্লটগুলি সমস্ত স্তরের খেলোয়াড়দের দ্বারা উপভোগ করা যেতে পারে, তারা শিক্ষানবিস বা পেশাদার কিনা। </p>-->
<!--                      <li><strong>টেবিল এবং তাসের খেলা</strong></li>-->
<!--                      <p>কোন সন্দেহ নেই যে টেবিল এবং কার্ড গেম পোর্টফোলিও হল সবচেয়ে জনপ্রিয় ক্যাসিনো গেম পোর্টফোলিওগুলির মধ্যে একটি৷ একটি টেবিল গেম এবং একটি কার্ড গেম উভয়ই গেম যা একটি টেবিলে খেলা হয় এবং/অথবা খেলা ব্যবহার করা হয় </p>-->
<!--                      <li><strong>পোকার</strong></li>-->
<!--                      <p>তাসের সাথে জুজু খেলার কারণে, এটি প্রযুক্তিগতভাবে একটি তাস খেলা। পোকার, যাইহোক, বেশিরভাগ অনলাইন ক্যাসিনো দ্বারা সাধারণত অন্য জুয়ার বিভাগ হিসাবে শ্রেণীবদ্ধ করা হয়। এটি একটি হাত (তাসের সেট) তৈরি করা জুজু খেলার সাধারণ উদ্দেশ্য যা অন্যান্য প্রতিযোগী হাতের তুলনায় উচ্চতর। </p>-->
<!--                      <li><strong>স্পেশালিটি গেমস</strong></li>-->
<!--                      <p>খেলোয়াড়দের পক্ষে বাংলাদেশী অনলাইন ক্যাসিনোগুলির মধ্যে অন্যান্য ক্যাসিনো গেমগুলি খুঁজে পাওয়া সম্ভব যা উপরের গেমের বিভাগগুলির মধ্যে পড়ে না৷ স্পেশালিটি গেমগুলি এই ধরনের গেমগুলিকে অন্তর্ভুক্ত করবে। এই পোর্টফোলিওতে ক্যাসিনো গেমের বিস্তৃত নির্বাচন রয়েছে। </p></ol>-->
<!--                    <h2>বি. বাংলাদেশে ভূমি-ভিত্তিক এবং অনলাইন ক্যাসিনোর তুলনা</h2>-->

<!--                    <p>ভূমি-ভিত্তিক ক্যাসিনো হল শারীরিক স্থাপনা যেখানে পৃষ্ঠপোষকরা বিভিন্ন ধরনের ক্যাসিনো গেম খেলতে পারেন, যেমন স্লট মেশিন, টেবিল গেম এবং জুজু। অন্যদিকে, অনলাইন ক্যাসিনো হল ভার্চুয়াল প্রতিষ্ঠান যা একই গেম অফার করে কিন্তু ইন্টারনেটের মাধ্যমে। </p>-->
<!--                    <p>যখন জমি-ভিত্তিক এবং অনলাইন ক্যাসিনোগুলির তুলনার কথা আসে, তখন বেশ কয়েকটি মূল পার্থক্য রয়েছে: <p>-->
<!--                    <ol>-->
<!--                      <li><strong>সুবিধা: </strong></li>-->
<!--                      <p>অনলাইন ক্যাসিনোগুলি আরও সুবিধাজনক কারণ ক্যাসিনো গেমগুলি নিজের ঘরে বা ইন্টারনেট সংযোগের সাথে যে কোনও জায়গায় অ্যাক্সেস করা যেতে পারে৷ ভূমি-ভিত্তিক ক্যাসিনোগুলির জন্য শারীরিক ভ্রমণের প্রয়োজন এবং সীমিত অপারেটিং ঘন্টা থাকতে পারে। </p>-->
<!--                      <li><strong>গেম নির্বাচন: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনোগুলি সাধারণত জমি-ভিত্তিক ক্যাসিনোগুলির চেয়ে বিস্তৃত গেমের অফার করে৷ </p>-->
<!--                      <li><strong>বোনাস এবং প্রচার: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনো খেলোয়াড়দের আকৃষ্ট করতে এবং ধরে রাখতে শীর্ষ গেমিং বোনাস এবং প্রচার অফার করে। </p>-->
<!--                      <li><strong>সামাজিক মিথস্ক্রিয়া: </strong> </li>-->
<!--                      <p>ভূমি-ভিত্তিক ক্যাসিনো একটি সামাজিক পরিবেশ প্রদান করে যেখানে খেলোয়াড়রা একে অপরের সাথে এবং ডিলারদের সাথে যোগাযোগ করতে পারে। অনলাইন ক্যাসিনো লাইভ ক্যাসিনো বাংলাদেশে আরও একক অভিজ্ঞতা প্রদান করে। </p>-->
<!--                      <p>এটি লক্ষ করা গুরুত্বপূর্ণ যে জুয়া আইন এবং ক্যাসিনো নিয়মগুলি দেশ থেকে দেশে পরিবর্তিত হয় এবং কিছু দেশে, যেমন বাংলাদেশের মতো, জমি-ভিত্তিক এবং অনলাইন ক্যাসিনো উভয়ই অবৈধ৷ স্থানীয় আইন ও বিধিগুলি বোঝা এবং মেনে চলা গুরুত্বপূর্ণ৷</p></ol>-->

<!--                    <h2>গ. অনলাইন ক্যাসিনো বেটিং এর সুবিধা এবং অসুবিধা</h2>-->

<!--                    <h2>অনলাইন ক্যাসিনো বেটিং এর সুবিধা: </h2>-->
<!--                    <ol>-->
<!--                      <li><strong>সুবিধা: </strong></li>-->
<!--                      <p>অনলাইন ক্যাসিনোগুলি যেকোন জায়গা থেকে ইন্টারনেট সংযোগের মাধ্যমে অ্যাক্সেস করা যেতে পারে, যা খেলোয়াড়দের নিজেদের ঘরে বসেই তাদের প্রিয় গেমগুলি উপভোগ করা সহজ করে তোলে৷ </p>-->
<!--                      <li><strong>গেম নির্বাচন: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনোগুলি সাধারণত জমি-ভিত্তিক ক্যাসিনোগুলির তুলনায় ঐতিহ্যগত এবং আধুনিক উভয় গেম সহ লাইভ ক্যাসিনো বাংলাদেশ গেমগুলির একটি বিস্তৃত পরিসর অফার করে। </p>-->
<!--                      <li><strong>বোনাস এবং প্রচার: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনো প্রায়ই বোনাস অফার করে, যেমন ওয়েলকাম বোনাস এবং ফ্রি স্পিন, খেলোয়াড়দের আকৃষ্ট করতে এবং ধরে রাখতে। </p>-->
<!--                      <li><strong>অজ্ঞাতনামা: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনো বেটিং খেলোয়াড়দের বেনামী থাকার অনুমতি দেয়, যা ক্যাসিনো জুয়া কার্যক্রম ব্যক্তিগত রাখতে পছন্দকারী খেলোয়াড়দের জন্য উপকারী হতে পারে। </p></ol>-->

<!--                    <h2>অনলাইন ক্যাসিনো বাজির অসুবিধা: </h2>-->
<!--                    <ol>-->
<!--                      <li><strong>নিরাপত্তা ঝুঁকি: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনো হ্যাকিং এবং অন্যান্য নিরাপত্তা হুমকির জন্য ঝুঁকিপূর্ণ হতে পারে, যা খেলোয়াড়দের ব্যক্তিগত এবং আর্থিক তথ্যকে ঝুঁকিতে ফেলতে পারে। </p>-->
<!--                      <li><strong>নিয়ন্ত্রণের অভাব: </strong> </li>-->
<!--                      <p>অনেক অনলাইন ক্যাসিনোতে যথাযথ নিয়ন্ত্রণ এবং তদারকির অভাব রয়েছে, যার ফলে অনৈতিক অনুশীলন হতে পারে, যেমন কারচুপি করা গেম এবং ধীর পেআউট। </p>-->
<!--                      <li><strong>আসক্তির ঝুঁকি: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনো বাজির সহজ অ্যাক্সেস এবং সুবিধা জুয়া আসক্তির ঝুঁকি বাড়াতে পারে। </p>-->
<!--                      <li><strong>সীমিত মিথস্ক্রিয়া: </strong> </li>-->
<!--                      <p>অনলাইন ক্যাসিনোগুলি আরও একক অভিজ্ঞতা প্রদান করে, যা জমি-ভিত্তিক ক্যাসিনোগুলির সামাজিক পরিবেশের চেয়ে কম আকর্ষণীয় এবং উপভোগ্য হতে পারে৷ </p></ol>-->

<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->
<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >IV. বাংলাদেশে সফল ক্যাসিনো বেটিং করার কৌশল</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <h2>ক. ব্যাঙ্করোল ম্যানেজমেন্টের গুরুত্ব: </h2>-->
<!--                    <p>ক্যাসিনো বেটিংয়ে ব্যাঙ্করোল ব্যবস্থাপনা গুরুত্বপূর্ণ কারণ এটি খেলোয়াড়দের তাদের খরচ নিয়ন্ত্রণ করতে, তাদের উপায়ের মধ্যে থাকতে এবং ক্ষতি কমাতে সাহায্য করে। তাদের ব্যাঙ্করোলকে কার্যকরভাবে পরিচালনা করার মাধ্যমে, খেলোয়াড়রা নিশ্চিত করতে পারে যে তাদের কাছে আর্থিক সমস্যা বা ঋণ এড়ানোর পাশাপাশি তাদের প্রিয় গেমগুলি খেলা চালিয়ে যাওয়ার জন্য যথেষ্ট তহবিল রয়েছে। </p>-->

<!--                    <h2>বি. অডস এবং হাউস এজ বোঝা</h2>-->
<!--                    <p>অডস এবং হাউস এজ হল ক্যাসিনো বাজির গুরুত্বপূর্ণ ধারণা কারণ এগুলি জয়ের সম্ভাবনা এবং ক্যাসিনোর জন্য গেমের লাভজনকতা নির্ধারণ করে। </p>-->
<!--                    <p>অডস লাইকের সাথে একটি ঘটনা ঘটার সম্ভাবনার অনুপাতকে বোঝায়এটা ঘটছে না. ক্যাসিনো বাজিতে, মতভেদ একটি বিজয়ী বাজির জন্য অর্থপ্রদান নির্ধারণ করে। </p>-->
<!--                    <p><strong>উদাহরণ:</strong> যদি একটি বাজি জেতার সম্ভাবনা 2:1 হয়, তাহলে প্রতি 1 ইউনিট বাজির জন্য, বাজি জিতলে পেআউট 2 ইউনিট হবে৷ </p>-->
<!--                    <p>হাউস এজ বলতে ক্যাসিনো প্লেয়ারের উপরে থাকা সুবিধাকে বোঝায়। তারপরে এটি একটি গেমের সত্যিকারের প্রতিকূলতা এবং খেলোয়াড়ের প্রাপ্ত প্রতিকূলতার মধ্যে পার্থক্য হিসাবে গণনা করা হয়। উদাহরণস্বরূপ, যদি একটি নির্দিষ্ট গেম জেতার সত্যিকারের প্রতিকূলতা 50% হয়, কিন্তু পেআউট 1:1 এ সেট করা হয়, তাহলে হাউস এজ 50% - 100% = -50%। এর মানে হল প্রতি 100 ইউনিট বাজির জন্য, ক্যাসিনো গড়ে 50 ইউনিট লাভ করবে।-->
<!--                      প্রতিকূলতা এবং ঘরের প্রান্ত বোঝা খেলোয়াড়দের জন্য গুরুত্বপূর্ণ কারণ এটি তাদের এমন গেমগুলি বেছে নিতে সাহায্য করে যেগুলি জয়ের সর্বোত্তম সম্ভাবনা প্রদান করে, পাশাপাশি তাদের ক্ষতি কমিয়ে দেয়। খেলোয়াড়দের সর্বদা নিম্ন ঘরের প্রান্ত এবং অনুকূল প্রতিকূলতার সাথে গেমগুলি সন্ধান করা উচিত এবং তাদের ব্যয়ের সীমা নির্ধারণ করা উচিত এবং কার্যকরভাবে তাদের ব্যাঙ্করোল পরিচালনা করা উচিত। </p>-->

<!--                    <h2>গ. লো হাউস এজ সহ গেম নির্বাচন করা</h2>-->
<!--                    <p>ক্যাসিনোতে আপনার জেতার সম্ভাবনা উন্নত করার একটি উপায় হল নিম্ন ঘরের প্রান্ত সহ গেমগুলি বেছে নেওয়া৷ ক্যাসিনো গেমিং হাউস এজ যত কম হবে, খেলোয়াড়ের জন্য প্রতিকূলতা তত ভালো হবে। নিম্ন ঘরের প্রান্ত সহ এখানে কয়েকটি জনপ্রিয় ক্যাসিনো গেম রয়েছে: </p>-->
<!--                    <ol>-->
<!--                      <li><strong>ব্ল্যাকজ্যাক: </strong></li>-->
<!--                      <p>সঠিক কৌশলের সাহায্যে, ব্ল্যাকজ্যাকের বাড়ির প্রান্তটি 0.5% পর্যন্ত কম হতে পারে।-->
<!--                      <li><strong>ব্যাকারট: </strong></li>-->
<!--                      <p>ব্যাকারের উপর বাজি ধরার সময় Baccarat-এর লো হাউস এজ 1.06% এবং প্লেয়ারের উপর বাজি ধরার সময় 1.24%।-->
<!--                      <li><strong>ক্র্যাপস: </strong></li>-->
<!--                      <p>বেটের উপর নির্ভর করে ক্র্যাপস-এর হাউস এজ অনেকটাই পরিবর্তিত হতে পারে, কিন্তু পাস লাইনে বাজি ধরার সময় এটি 1.41% পর্যন্ত কম হতে পারে।-->
<!--                      <li><strong>ভিডিও পোকার: </strong></li>-->
<!--                      <p>ভিডিও পোকার মেশিনগুলি যেগুলি একটি সম্পূর্ণ পেটেবল অফার করে তাদের ঘরের প্রান্ত 0.46% কম হতে পারে।-->
<!--                      <li><strong>রুলেট: </strong></li>-->
<!--                      <p>আমেরিকান সংস্করণের তুলনায় রুলেটের ইউরোপীয় সংস্করণে নিম্ন ঘরের প্রান্ত রয়েছে 2.7%, যার ঘরের প্রান্ত 5.26%।</p>-->
<!--                      <p>খেলোয়াড়দের প্রতিটি খেলার নিয়ম এবং তারতম্য সম্পর্কেও সচেতন হওয়া উচিত, কারণ এটি বাড়ির প্রান্তকে ব্যাপকভাবে প্রভাবিত করতে পারে। উদাহরণস্বরূপ, কিছু ব্ল্যাকজ্যাক গেমের এমন নিয়ম থাকতে পারে যা বাড়ির প্রান্তকে বাড়িয়ে দেয়, যেমন স্ট্যান্ডার্ড 3:2 এর পরিবর্তে ব্ল্যাকজ্যাকের জন্য 6:5 পেআউট। </p>-->
<!--                      <p>এটা মনে রাখা গুরুত্বপূর্ণ যে এমনকি একটি নিম্ন ঘরের প্রান্ত থাকা সত্ত্বেও, ক্যাসিনোর জন্য একটি অন্তর্নির্মিত সুবিধা রয়েছে এবং প্রতিটি গেমের ফলাফল চূড়ান্তভাবে সুযোগ দ্বারা নির্ধারিত হয়। খেলোয়াড়দের উচিত তাদের ব্যাঙ্করোলকে কার্যকরভাবে পরিচালনা করা এবং ক্ষতির পেছনে ছুটতে যাওয়া এড়ানো উচিত। </p></ol>-->

<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->

<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >ভি. বাংলাদেশের ক্যাসিনোতে বাজি ধরার সময় সাধারণ ভুলগুলি এড়াতে হবে</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <h2>ক. পরাজয় তাড়া</h2>-->
<!--                    <p>ক্যাসিনো বাজিতে ক্ষতির পিছনে ছুটানো একটি বিপজ্জনক আচরণ, কারণ এতে আগের ক্ষতি পুনরুদ্ধার করার প্রচেষ্টায় বাজি রাখা চালিয়ে যাওয়া জড়িত। এটি প্রায়শই বড় ক্ষতির দিকে পরিচালিত করে, কারণ খেলোয়াড়রা তাদের অর্থ ফেরত পাওয়ার চেষ্টা করার জন্য আরও বেশি বিনিয়োগ করে। </p>-->
<!--                    <p>ক্যাসিনো বাজিতে ক্ষতির পেছনে ছুটতে যাওয়ার কয়েকটি কারণ এখানে রয়েছে: </p>-->
<!--                    <ol>-->
<!--                      <li><strong>আবেগজনিত সম্পৃক্ততা: </strong></li>-->
<!--                      <p> ক্ষতির পিছনে ছুটতে প্রায়ই খেলার ফলাফলে আবেগগতভাবে বিনিয়োগ করা জড়িত থাকে, যা বিচারকে মেঘলা করে এবং দুর্বল সিদ্ধান্ত গ্রহণের দিকে নিয়ে যেতে পারে। </p>-->
<!--                      <li><strong>বর্ধিত ব্যয়: </strong></li>-->
<!--                      <p>বাজি রাখা চালিয়ে যাওয়ার মাধ্যমে, খেলোয়াড়রা তাদের ব্যয় এবং হারানোর পরিমাণ বৃদ্ধি করে। </p>-->
<!--                      <li><strong>জেতার সম্ভাবনা কমে গেছে: </strong></li>-->
<!--                      <p>একজন খেলোয়াড় যত বেশি সময় ধরে হারের পিছনে ছুটতে থাকে, তাদের জেতার সম্ভাবনা তত কম হয়, কারণ জয়ের সম্ভাবনা সুযোগ দ্বারা নির্ধারিত হয় এবং অতীতের ফলাফল দ্বারা প্রভাবিত হয় না। </p></ol>-->

<!--                    <h2>বি. পণ করার সময় অ্যালকোহল পান করা</h2>-->
<!--                    <p>বেটিং করার সময় অ্যালকোহল পান করা বিভিন্ন কারণে ক্ষতিকারক হতে পারে: </p>-->
<!--                    <li><strong>বিচারকে দুর্বল করে: </strong></li>-->
<!--                    <p>অ্যালকোহল বিচারকে ক্ষতিগ্রস্থ করে, যা দুর্বল সিদ্ধান্ত গ্রহণের দিকে নিয়ে যেতে পারে এবং বাজিতে ঝুঁকি নেওয়ার আচরণ বৃদ্ধি করতে পারে। এর ফলে বড় ক্ষতি হতে পারে এবং জেতার সম্ভাবনা কমে যেতে পারে। </p>-->
<!--                    <li><strong>মেঘের উপলব্ধি: </strong></li>-->
<!--                    <p>অ্যালকোহলও ধারণাকে মেঘে পরিণত করতে পারে, যার ফলে প্রতিকূলতা এবং ঘরের প্রান্তের ভুল বোঝাবুঝির পাশাপাশি জেতার সম্ভাবনার ভুল অনুমান। </p>-->
<!--                    <li><strong>আত্ম-নিয়ন্ত্রণ হ্রাস: </strong></li>-->
<!--                    <p>অ্যালকোহল আত্ম-নিয়ন্ত্রণ হ্রাস করে, যা অতিরিক্ত ব্যয় এবং তাড়া করার লোকসানের দিকে নিয়ে যেতে পারে, এমনকি খেলোয়াড় জানে যে তাদের থামানো উচিত। </p>-->
<!--                    <li><strong>আসক্তির ঝুঁকি বৃদ্ধি: </strong></li>-->
<!--                    <p>বেটিং করার সময় মদ্যপান একটি জুয়ার আসক্তি তৈরির ঝুঁকির দিকে নিয়ে যেতে পারে, কারণ অ্যালকোহল এবং বাজির সংমিশ্রণ একটি শক্তিশালী মানসিক অভিজ্ঞতা তৈরি করতে পারে যা প্রতিরোধ করা কঠিন।-->
<!--                      সামগ্রিকভাবে, বাজি ধরার সময় অ্যালকোহল পান করা খেলোয়াড়ের স্বাস্থ্য এবং তাদের আর্থিক উভয়ের জন্যই মারাত্মক পরিণতি ঘটাতে পারে। পণ করার সময় মদ্যপান এড়িয়ে চলা বা এই ক্ষতিকর প্রভাবগুলি এড়াতে অ্যালকোহল সেবন সীমিত করা ভাল। </p></ol>-->

<!--                    <h2>গ. সীমা নির্ধারণ না করা এবং তাদের সাথে লেগে থাকা</h2>-->
<!--                    <p>ক্যাসিনোতে বাজি ধরার সময় সীমা নির্ধারণ না করা এবং তাদের সাথে লেগে থাকা বিভিন্ন উপায়ে ক্ষতিকারক হতে পারে: </p>-->
<!--                    <ol>-->
<!--                      <li><strong>অতিব্যয়: </strong></li>-->
<!--                      <p>সীমা ছাড়া, আপনার সামর্থ্যের চেয়ে বেশি অর্থ ব্যয় করা এবং হারানো সহজ, যা আর্থিক চাপের দিকে পরিচালিত করে এবং সম্ভাব্য এমনকি আর্থিক ক্ষতির দিকে নিয়ে যায়। </p>-->
<!--                      <li><strong>পরাজয় তাড়া করা: </strong></li>-->
<!--                      <p>সীমা ছাড়া, লোকসান তাড়া করাও সহজ, যা হারানো টাকা ফেরত পাওয়ার চেষ্টা করার জন্য একটি বিপজ্জনক এবং অকার্যকর উপায়। </p>-->
<!--                      <li><strong>কমানো উপভোগ: </strong></li>-->
<!--                      <p>সীমা ছাড়া, পণ করা কম আনন্দদায়ক হয়ে উঠতে পারে, কারণ ফোকাস মজা করা থেকে হারিয়ে যাওয়া টাকা ফেরত পাওয়ার দিকে চলে যায়। </p>-->
<!--                      <li><strong>আসক্তি:</strong></li>-->
<!--                      <p>সীমা নির্ধারণ না করাও জুয়া খেলার আসক্তি তৈরির ঝুঁকি বাড়াতে পারে, কারণ খেলোয়াড়রা খেলা চালিয়ে যাওয়ার প্রয়োজন অনুভব করতে পারে।-->
<!--                        খেলোয়াড়দের বাজি ধরা শুরু করার আগে সীমা নির্ধারণ করা এবং তাদের সাথে লেগে থাকা গুরুত্বপূর্ণ, কারণ এটি তাদের অতিরিক্ত খরচ করা, ক্ষতির পেছনে ছুটতে এবং সম্ভাব্য ক্ষতিকর আচরণ এড়াতে সাহায্য করবে। সময় বা অর্থের পরিপ্রেক্ষিতে সীমা নির্ধারণ করা যেতে পারে এবং খেলোয়াড়ের আর্থিক পরিস্থিতি এবং ব্যক্তিগত মূল্যবোধের বাস্তবসম্মত এবং প্রতিফলিত হওয়া উচিত। </p></ol>-->

<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->

<!--                <v-expansion-panel class="footer-expansion-panel">-->
<!--                  <v-expansion-panel-header >VI. উপসংহার</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content class="footer-expansion-panel">-->
<!--                    <h2>ক. মূল পয়েন্টের রিক্যাপ</h2>-->
<!--                    <ol>-->
<!--                      <li><strong>বাংলাদেশে অবৈধ: </strong></li>-->
<!--                      <p>ক্যাসিনো বেটিং বাংলাদেশে বেআইনি, এবং যারা এতে অংশগ্রহণ করে তাদের জরিমানা এবং কারাদণ্ডের ঝুঁকি রয়েছে। </p>-->
<!--                      <li><strong>অভিডস এবং হাউস এজ বোঝা: </strong></li>-->
<!--                      <p>সচেতন সিদ্ধান্ত নেওয়ার জন্য এবং তাদের জেতার সম্ভাবনাকে সর্বাধিক করার জন্য খেলোয়াড়দের জন্য তাদের খেলার প্রতিকূলতা এবং হাউস এজ বোঝা গুরুত্বপূর্ণ। </p>-->
<!--                      <li><strong>দায়িত্বপূর্ণ পণ অভ্যাস:</strong></li>-->
<!--                      <p>দায়িত্বের সাথে বাজি ধরা, যেমন সীমা নির্ধারণ করা এবং লেগে থাকা, ক্ষতির পেছনে ছুটতে যাওয়া এবং বাজি ধরার সময় অ্যালকোহল এড়ানো, অতিরিক্ত খরচ, আসক্তি এবং অন্যান্য ক্ষতিকারক আচরণ প্রতিরোধ করতে সাহায্য করে। </p>-->
<!--                      <li><strong>ভূমি-ভিত্তিক এবং অনলাইন ক্যাসিনোগুলির তুলনা:</strong></li>-->
<!--                      <p>ভূমি-ভিত্তিক এবং অনলাইন ক্যাসিনো উভয়েরই নিজস্ব স্বতন্ত্র সুবিধা এবং অসুবিধা রয়েছে এবং কোন ধরনের ক্যাসিনো খেলতে হবে সে বিষয়ে সিদ্ধান্ত নেওয়ার আগে খেলোয়াড়দের এগুলি বোঝা এবং তুলনা করা গুরুত্বপূর্ণ। </p></ol>-->

<!--                    <h2>বি. বাংলাদেশে ক্যাসিনো বেটিং নিয়ে চূড়ান্ত চিন্তা</h2>-->
<!--                    <p>উপসংহারে, ক্যাসিনো বেটিং বাংলাদেশে জুয়া খেলার একটি জনপ্রিয় রূপ, যদিও এটি অবৈধ। যাইহোক, খেলোয়াড়দের অবৈধ বাজিতে অংশগ্রহণের পরিণতি সম্পর্কে সচেতন হওয়া উচিত, যার মধ্যে জরিমানা এবং কারাদণ্ড অন্তর্ভুক্ত থাকতে পারে। </p>-->
<!--                    <p>যারা ক্যাসিনো বেটিংয়ে অংশগ্রহণ করতে পছন্দ করেন, তাদের জন্য গেমের প্রতিকূলতা এবং হাউস এজ বোঝা গুরুত্বপূর্ণ, সেইসাথে দায়িত্বশীল বাজি ধরার অভ্যাসগুলি অনুশীলন করা, যেমন সীমা নির্ধারণ করা এবং লেগে থাকা, ক্ষতির তাড়া এড়ানো এবং এড়ানো। পণ করার সময় পান করা। </p>-->
<!--                    <p>ভূমি-ভিত্তিক এবং অনলাইন ক্যাসিনো উভয়ের সুবিধা এবং অসুবিধাগুলি তুলনা করাও গুরুত্বপূর্ণ, কারণ প্রতিটিরই নিজস্ব অনন্য সুবিধা এবং অসুবিধা রয়েছে৷-->
<!--                      সামগ্রিকভাবে, যদিও ক্যাসিনো বাজি একটি মজার এবং উত্তেজনাপূর্ণ অভিজ্ঞতা হতে পারে, এটি সতর্কতার সাথে যোগাযোগ করা এবং একটি নিরাপদ এবং আনন্দদায়ক অভিজ্ঞতা নিশ্চিত করতে দায়িত্বশীল অভ্যাস অনুশীলন করা গুরুত্বপূর্ণ। </p>-->

<!--                    <h2>গ. দায়িত্বের সাথে বাজি ধরতে এবং মজা করতে উত্সাহিত করুন</h2>-->
<!--                    <p>একটি মজাদার অভিজ্ঞতার জন্য দায়িত্বশীল পণ গুরুত্বপূর্ণ কারণ এটি নিশ্চিত করতে সাহায্য করে যে আপনার সামর্থ্যের চেয়ে নিয়ন্ত্রণ হারানো এবং আরও বেশি অর্থ হারানোর পরিবর্তে অভিজ্ঞতা উপভোগ করার উপর ফোকাস থাকে। সীমা নির্ধারণ করে এবং লেগে থাকা, ক্ষতির পেছনে ছুটতে থাকা এবং বাজি ধরার সময় অ্যালকোহল এড়ানোর মাধ্যমে, খেলোয়াড়রা মানসিক চাপ কমাতে পারে এবং উপভোগ বাড়াতে পারে, বাজি ধরাকে একটি মজাদার এবং উত্তেজনাপূর্ণ অভিজ্ঞতা তৈরি করে। </p>-->

<!--                    <p>ইন্ডিয়ান প্রিমিয়ার লিগ | বাংলাদেশ ক্রিকেট বেটিং | স্লট গেম বাংলাদেশ</p>-->

<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->
<!--              </v-expansion-panels>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <v-row v-if="$route.name == routeName.VIP">
            <v-col v-if="i18n.locale == 'en'">
                <h1 class="h2">Claim Your KRIKYA Rewards Online On Time</h1>
                <br/>
                <p>As of right now, KRIKYA Rewards will be the only resource you ever need for gaming information. Why? </p>
                <p>We at krikya88 are committed to providing you, the online gambler, with the best in online gaming. We will only direct you to the safest, most reliable, and secure set of online casinos rooms available worldwide. </p>
                <p>Rewards Casino has what you're searching for, whether you're an experienced online gambler or a novice looking for your first casino room to play at. </p> 
                <h2>Experience Only The Best With krikya.group</h2>
                <p>At krikya.group, we take great pride in offering you a selection of online casinos that have won our trust and loyalty. </p>
                <p>We sincerely hope that your stay with us will be educational and useful to you as you look for an online casino room that you can rely on and enjoy playing at with the assurance that you are doing it at one of the top online gambling destinations and KRIKYA VIP challenge available. </p> <p>Please confirm that your gear complies with the specifications listed beneath the download section. Enjoy! </p><p>Online Casino {{ currentYear }} | Live Casino Online Bangladesh | Bangladesh Cricket Betting | Online Slots Bangladesh</p>
            </v-col>
            <v-col v-else-if="i18n.locale == 'bd'">
                <h1 class="h2">সময়মতো অনলাইনে আপনার KRIKYA পুরস্কার দাবি করুন</h1>
                <br/>
                <p>এই মুহুর্তে, KRIKYA পুরস্কারই হবে একমাত্র সম্পদ যা আপনার গেমিং তথ্যের জন্য প্রয়োজন। কেন?</p>
                <p> আমরা krikya88-এ আপনাকে, অনলাইন জুয়াড়ি, অনলাইন গেমিংয়ে সেরা প্রদান করতে প্রতিশ্রুতিবদ্ধ। আমরা আপনাকে শুধুমাত্র বিশ্বব্যাপী উপলব্ধ অনলাইন ক্যাসিনো কক্ষগুলির সবচেয়ে নিরাপদ, সবচেয়ে নির্ভরযোগ্য এবং সুরক্ষিত সেটের দিকে পরিচালিত করব।</p>
                <p> রিওয়ার্ডস ক্যাসিনোতে আপনি যা খুঁজছেন তা রয়েছে, আপনি একজন অভিজ্ঞ অনলাইন জুয়াড়ি হোন বা আপনার প্রথম ক্যাসিনো রুম যেখানে খেলার জন্য খুঁজছেন একজন নবজাতক।</p>
                <h2>krikya.group-এর সাথে শুধুমাত্র সেরা অভিজ্ঞতা নিন </h2>
                <p>krikya.group-এ, আমরা আপনাকে অনলাইন ক্যাসিনোগুলির একটি বাছাই করার জন্য অফার করছি যা আমাদের বিশ্বাস এবং বিশ্বস্ততা জিতেছে।</p>
                <p> আমরা আন্তরিকভাবে আশা করি যে আমাদের সাথে আপনার থাকা আপনার জন্য শিক্ষামূলক এবং উপযোগী হবে কারণ আপনি একটি অনলাইন ক্যাসিনো রুম খুঁজছেন যার উপর আপনি নির্ভর করতে পারেন এবং খেলা উপভোগ করতে পারেন এই নিশ্চয়তার সাথে যে আপনি এটি সেরা অনলাইন জুয়ার গন্তব্যগুলির একটিতে করছেন এবং KRIKYA VIP চ্যালেঞ্জ উপলব্ধ৷</p>
                <p> অনুগ্রহ করে নিশ্চিত করুন যে আপনার গিয়ার ডাউনলোড বিভাগের নীচে তালিকাভুক্ত বৈশিষ্ট্যগুলি মেনে চলছে৷ উপভোগ করুন!</p>
                <p>অনলাইন ক্যাসিনো {{ currentYear }} | লাইভ ক্যাসিনো অনলাইন বাংলাদেশ | বাংলাদেশ ক্রিকেট বাজি | অনলাইন স্লট বাংলাদেশ</p>
            </v-col>
        </v-row>
<!--        <v-row v-else-if="$route.name == routeName.CRICKET">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Bangladesh Cricket Betting | Cricket Casino {{ currentYear }} | Cricket Betting Site Bangladesh | Cricket Exchange Download | Best Cricket Betting App for Android | Cricket Exchange | Trusted Best Online Cricket Exchange </h1>-->
<!--                <br/>-->
<!--                <h2>Find The Best Cricket Betting In Bangladesh {{ currentYear }}</h2>-->
<!--                <p>Are you looking for the top Bangladesh Cricket Betting site? You've arrived at the right place. You may anticipate deposits in taka, lucrative bonuses, hundreds of markets for sports like football and cricket, and trustworthy cricket casino licenses at our top cricket betting site Bangladesh.</p>-->
<!--                <p>This Bangladesh Cricket Betting is fully secure and come highly recommended by our professionals. </p>-->
<!--                <p>Register at one of our Bangladeshi betting sites, or keep reading to learn precise details about all other crucial elements of Bangladeshi online betting and Cricket Exchange.</p>-->
<!--                <h3>Main Features</h3>-->
<!--                <ul>-->
<!--                <li>fast registration from a phone or tablet on Cricket Exchange</li>-->
<!--                <li>excellent live stream quality</li>-->
<!--                <li>Accessibility to round-the-clock technical help; and management of betting history.</li>-->
<!--                <li>Free live streaming of Best Cricket Betting games on the bookmaker's website</li>-->
<!--                <li>A cash-out option</li>-->
<!--                <li>The ability to access the site in other languages</li>-->
<!--                <li>A custom app.</li>-->
<!--                </ul>-->
<!--                <p>Visit our Cricket Exchange Download website at /cricket/ to get in touch with us. </p>-->
<!--                <p>Indian Premier League | Bangladesh Live Casino | Online Slot Game Bangladesh</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2"> ক্রিকেট ক্যাসিনো | ক্রিকেট বেটিং সাইট বাংলাদেশ | ক্রিকেট এক্সচেঞ্জ ডাউনলোড | বাংলাদেশ ক্রিকেট বাজি | সেরা ক্রিকেট বেটিং | ক্রিকেট এক্সচেঞ্জ</h1>-->
<!--                <br/>-->
<!--                <h2>বাংলাদেশে {{ currentYear }} সালের সেরা ক্রিকেট বেটিং খুঁজুন</h2>-->
<!--                <p> আপনি কি সেরা বাংলাদেশ ক্রিকেট বাজি সাইট খুঁজছেন? আপনি সঠিক জায়গায় পৌঁছেছেন. আপনি টাকা জমা, লোভনীয় বোনাস, ফুটবল এবং ক্রিকেটের মতো খেলার জন্য শত শত বাজার এবং বিশ্বস্ত ক্রিকেট ক্যাসিনো লাইসেন্স আশা করতে পারেন। শীর্ষ ক্রিকেট বেটিং সাইট বাংলাদেশ।</p>-->
<!--                <p>এই বাংলাদেশ ক্রিকেট বেটিং সম্পূর্ণ নিরাপদ এবং আমাদের পেশাদারদের দ্বারা অত্যন্ত সুপারিশ করা হয়।</p>-->
<!--                <p> আমাদের বাংলাদেশী বেটিং সাইটের একটিতে নিবন্ধন করুন, অথবা বাংলাদেশী অনলাইন বেটিং এর অন্যান্য সমস্ত গুরুত্বপূর্ণ উপাদান সম্পর্কে সুনির্দিষ্ট বিবরণ জানতে পড়তে থাকুন এবং ক্রিকেট এক্সচেঞ্জ।</p>-->
<!--                <h3> প্রধান বৈশিষ্ট্য</h3>-->
<!--                <ul>-->
<!--                <li>একটি ফোন বা ট্যাবলেট থেকে দ্রুত নিবন্ধন ক্রিকেট এক্সচেঞ্জ</li>-->
<!--                <li>চমৎকার লাইভ স্ট্রিম মান</li>-->
<!--                <li> রাউন্ড-দ্য-ক্লক প্রযুক্তিগত সহায়তার অ্যাক্সেসযোগ্যতা; এবং বাজির ইতিহাসের ব্যবস্থাপনা।</li>-->
<!--                <li> বুকমেকারের ওয়েবসাইটে সেরা ক্রিকেট বেটিং গেমগুলির বিনামূল্যে লাইভ স্ট্রিমিং </li>-->
<!--                <li>একটি নগদ-আউট বিকল্প</li>-->
<!--                <li>অন্যান্য ভাষায় সাইট অ্যাক্সেস করার ক্ষমতা</li>-->
<!--                <li> একটি কাস্টম অ্যাপ।</li>-->
<!--                </ul>-->
<!--                <p> আমাদের পরিদর্শন করুন  ক্রিকেট এক্সচেঞ্জ ডাউনলোড-->
<!--                 আমাদের সাথে যোগাযোগ করতে ওয়েবসাইট /cricket/. </p>-->
<!--                <p>ইন্ডিয়ান প্রিমিয়ার লিগ | বাংলাদেশ লাইভ ক্যাসিনো  | অনলাইন স্লট গেম বাংলাদেশ</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-row v-else-if="$route.name == routeName.LOTTERY">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Play Keno Lottery and Win in Bangladesh</h1>-->
<!--                <br/>-->
<!--                <p>The dynamic lottery game known as Keno 24/7 is one of the most popular. This game, provided to you by krikya.group and a fun combination of a lottery and bingo, offers jackpots worth up to several crores. .</p>-->
<!--                <p>We will recommend you the best-->
<!--                Keno Lottery Game that will aid you in buying your first Keno lottery ticket.</p>-->
<!--                <h2>Playing Keno With Krikya Nonstop</h2>-->
<!--                <p>Like other players who play and win from Bangladesh and the rest of the world, you must first register on the krikya online betting website or app to play the Keno lottery. Now that the draw occurs every four minutes, every day of the week, you are free to begin any day and any time.</p>-->
<!--                <p>Keep playing our generous keno lottery game to increase your chances of winning and take your prizes home in {{ currentYear }}. Get the best online lottery result now! .</p>-->
<!--                <p>Online Casino Bangladesh | Live Casino Bangladesh | cricket casino | online slots bangladesh</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2">কেনো লটারি খেলুন এবং বাংলাদেশে জিতুন </h1>-->
<!--                <br/>-->
<!--                <p>Keno 24/7 নামে পরিচিত ডায়নামিক লটারি গেমটি অন্যতম জনপ্রিয়।এই গেমটি, আপনাকে krikya.group দ্বারা সরবরাহ করা হয়েছে এবং একটি লটারি এবং বিঙ্গোর একটি মজার সংমিশ্রণ, কয়েক কোটি টাকা পর্যন্ত মূল্যের জ্যাকপট অফার করে..</p>-->
<!--                <p>আমরা আপনাকে সেরা সুপারিশ করবে কেনো লটারি গেম যা আপনাকে আপনার প্রথম Keno লটারির টিকিট কিনতে সাহায্য করবে.</p>-->
<!--                <h2>ক্রিক্যা ননস্টপ দিয়ে কেনো খেলা</h2>-->
<!--                <p>অন্যান্য খেলোয়াড়দের মতো যারা বাংলাদেশ এবং বাকি বিশ্ব থেকে খেলে এবং জয়লাভ করে, কেনো লটারি খেলতে আপনাকে প্রথমে ক্রিক্য অনলাইন বেটিং ওয়েবসাইট বা অ্যাপে নিবন্ধন করতে হবে।এখন যেহেতু প্রতি চার মিনিটে, সপ্তাহের প্রতিটি দিন ড্র হয়, আপনি যে কোনো দিন এবং যে কোনো সময় শুরু করতে পারবেন.</p>-->
<!--                <p>আপনার জেতার সম্ভাবনা বাড়াতে এবং {{ currentYear }} সালে আপনার পুরষ্কার ঘরে তুলতে আমাদের উদার কেনো লটারি গেম খেলতে থাকুন। এখনই সেরা অনলাইন লটারির ফলাফল পান!.</p>-->
<!--                <p>অনলাইনক্যাসিনোবাংলাদেশ | লাইভ ক্যাসিনো বাংলাদেশ | ক্রিকেট্যাসিনো | অনলাইনস্লট বাংলাদেশ</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--      <v-row v-else-if="$route.name == routeName.CRASH">-->
<!--        <v-col v-if="i18n.locale == 'en'">-->
<!--          <h1 class="h2">Play Keno Crash and Win in Bangladesh</h1>-->
<!--          <br/>-->
<!--          <p>The dynamic Crash game known as Keno 24/7 is one of the most popular. This game, provided to you by krikya.group and a fun combination of a Crash and bingo, offers jackpots worth up to several crores. .</p>-->
<!--          <p>We will recommend you the best-->
<!--            Keno Crash Game that will aid you in buying your first Keno Crash ticket.</p>-->
<!--          <h2>Playing Keno With Krikya Nonstop</h2>-->
<!--          <p>Like other players who play and win from Bangladesh and the rest of the world, you must first register on the krikya online betting website or app to play the Keno Crash. Now that the draw occurs every four minutes, every day of the week, you are free to begin any day and any time.</p>-->
<!--          <p>Keep playing our generous keno Crash game to increase your chances of winning and take your prizes home in {{ currentYear }}. Get the best online Crash result now! .</p>-->
<!--          <p>Online Casino Bangladesh | Live Casino Bangladesh | cricket casino | online slots bangladesh</p>-->
<!--        </v-col>-->
<!--        <v-col v-else-if="i18n.locale == 'bd'">-->
<!--          <h1 class="h2">কেনো লটারি খেলুন এবং বাংলাদেশে জিতুন </h1>-->
<!--          <br/>-->
<!--          <p>Keno 24/7 নামে পরিচিত ডায়নামিক লটারি গেমটি অন্যতম জনপ্রিয়।এই গেমটি, আপনাকে krikya.group দ্বারা সরবরাহ করা হয়েছে এবং একটি লটারি এবং বিঙ্গোর একটি মজার সংমিশ্রণ, কয়েক কোটি টাকা পর্যন্ত মূল্যের জ্যাকপট অফার করে..</p>-->
<!--          <p>আমরা আপনাকে সেরা সুপারিশ করবে কেনো লটারি গেম যা আপনাকে আপনার প্রথম Keno লটারির টিকিট কিনতে সাহায্য করবে.</p>-->
<!--          <h2>ক্রিক্যা ননস্টপ দিয়ে কেনো খেলা</h2>-->
<!--          <p>অন্যান্য খেলোয়াড়দের মতো যারা বাংলাদেশ এবং বাকি বিশ্ব থেকে খেলে এবং জয়লাভ করে, কেনো লটারি খেলতে আপনাকে প্রথমে ক্রিক্য অনলাইন বেটিং ওয়েবসাইট বা অ্যাপে নিবন্ধন করতে হবে।এখন যেহেতু প্রতি চার মিনিটে, সপ্তাহের প্রতিটি দিন ড্র হয়, আপনি যে কোনো দিন এবং যে কোনো সময় শুরু করতে পারবেন.</p>-->
<!--          <p>আপনার জেতার সম্ভাবনা বাড়াতে এবং {{ currentYear }} সালে আপনার পুরষ্কার ঘরে তুলতে আমাদের উদার কেনো লটারি গেম খেলতে থাকুন। এখনই সেরা অনলাইন লটারির ফলাফল পান!.</p>-->
<!--          <p>অনলাইনক্যাসিনোবাংলাদেশ | লাইভ ক্যাসিনো বাংলাদেশ | ক্রিকেট্যাসিনো | অনলাইনস্লট বাংলাদেশ</p>-->
<!--        </v-col>-->
<!--      </v-row>-->
        <v-row v-else-if="$route.name == routeName.PROMOTION">
            <v-col v-if="i18n.locale == 'en'">
                <h1 class="h2">Cricket Betting Promotions | Cricket Betting Welcome Bonus | Cricket Betting App Free Bonus | Free 100 Sign Up Bonus No Deposit Cricket | Instant Register Cricket Bonus No Deposit</h1>
                <br/>
                <h2>Get Instant Register Cricket Bonus No Deposit in {{ currentYear }}</h2>
                <p>There is no need for players to search farther for betting sites with Instant Register Cricket Bonus No Deposit. Today, there is tremendous competition for positions among online bookies.</p>
                <p>As a result, many betting sites will provide new customers with some sort of incentive. Indian gamblers can now benefit from a variety of cricket Cricket Betting Promotions , Cricket Betting Welcome Bonus , and free bet promos. Betting sites with no deposit bonuses are one that gamblers might not be familiar with.</p>
                <h3>Enjoy The Best Promotions Available At krikya.group</h3>
                <p>Such an offer, which is more frequently related to online casinos, demands no wagers from customers. This makes it considerably more challenging to locate. When it is available, though, it is well worth using. </p>
                <p>Even with a Cricket Betting App Free Bonus, winnings are not guaranteed, but many bettors, this kind of deal resemble the proverbial "holy grail." </p>
                <p>Would you like to take Free 100 Sign Up Bonus No Deposit Cricket? Contact us today.</p>
                <p>online casino {{ currentYear }} | Live Casino Online Bangladesh | Bangladesh Cricket Betting | Online Slot Bangladesh</p>
            </v-col>
            <v-col v-else-if="i18n.locale == 'bd'">
                <h1 class="h2">ক্রিকেট বেটিং প্রচার | ক্রিকেট বেটিং স্বাগতম বোনাস | ক্রিকেট বেটিং অ্যাপ বিনামূল্যে বোনাস {{ currentYear }} | বিনামূল্যে 100 সাইন আপ বোনাস নো ডিপোজিট ক্রিকেট | ইনস্ট্যান্ট রেজিস্টার ক্রিকেট বোনাস নো ডিপোজিট</h1>
                <br/>
                <h2>{{ currentYear }} সালেইনস্ট্যান্টরেজিস্টারক্রিকেটবোনাসনোডিপোজিটপান</h2>
                <p>খেলোয়াড়দের ইনস্ট্যান্ট রেজিস্টার ক্রিকেট বোনাস কোন ডিপোজিট সহবেটিংসাইটগুলিরজন্যআরওঅনুসন্ধানকরারদরকারনেই৷আজ, অনলাইনবুকিদেরমধ্যেপদেরজন্যপ্রচণ্ডপ্রতিযোগিতাচলছে.</p>
                <p>ফলস্বরূপ, অনেকবেটিংসাইটনতুনগ্রাহকদেরকিছুধরণেরপ্রণোদনাপ্রদানকরবে।ভারতীয়জুয়াড়িরাএখনবিভিন্নধরনের ক্রিকেট ক্রিকেট বেটিং প্রচার, ক্রিকেট বেটিং স্বাগতম বোনাস, এবংবিনামূল্যেরবাজিপ্রচার।কোনোডিপোজিটবোনাসছাড়াইবাজিধরারসাইটগুলিএমনএকটিযাজুয়াড়িরাপরিচিতনাও হতেপারে৷.</p>
                <h3>krikya.group-এউপলব্ধসেরাপ্রচারগুলিউপভোগকরুন৷</h3>
                <p>এইধরনেরএকটিঅফার, যাপ্রায়শইঅনলাইনক্যাসিনোগুলিরসাথেসম্পর্কিত, গ্রাহকদেরকাছথেকেকোনওবাজিরদাবিকরেনা৷এটিএটিসনাক্তকরাযথেষ্টচ্যালেঞ্জিংকরেতোলে।এটিউপলব্ধহ লে, যদিও, এটিব্যবহারকরাভাল.</p>
                <p>এমনকিএকটিক্রিকেট বেটিং অ্যাপ বিনামূল্যে বোনাস দিয়েও, জয়েরনিশ্চয়তানেই, তবেঅনেকবাজির, এইধরনেরচুক্তিপ্রবাদেরঅনুরূপগ্রিল."</p>
                <p>আপনিকিবিনামূল্যে 100 সাইনআপবোনাসনোডিপোজিটক্রিকেটনিতেচান?আজআমাদেরসাথেযোগাযোগকরুন.</p>
                <p>অনলাইনক্যাসিনো {{ currentYear }} | লাইভ ক্যাসিনো অনলাইন বাংলাদেশ  | বাংলাদেশক্রিকেটবাজি | অনলাইন স্লট বাংলাদেশ </p>
            </v-col>
        </v-row>
<!--        <v-row v-else-if="$route.name == routeName.SLOT">-->
<!--            <v-col cols="12" v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Online Slots Game Casino Bangladesh | Online Slot Bangladesh</h1>-->
<!--                <br/>-->
<!--                <h2>Enjoy the Bangladesh Online Slots Play in {{ currentYear }}</h2>-->
<!--                <p>The best online slots Bangladesh is krikya.group Casino. You may play the most thrilling casino slot games on our site.</p>-->
<!--                <p>Play renowned online slots at casinos Players from Bangladesh can browse our extensive selection of Online Slot Bangladesh games. The most exciting slot machines that suit you can be found here.</p>-->
<!--                <h3>Elegant Online Slots Bonuses To Win</h3>-->
<!--                <p>You can get classy online promotional prizes with exciting slot bonuses at krikya.group online casino. You can play all of the games at krikya casino without any risk.</p>-->
<!--                <p>We ensure that when you play at our online casino gaming site, you engage in the safest casino games and don't fall victim to any fraud.</p>-->
<!--                <h3>Why krikya.group?</h3>-->
<!--                <p><ol>-->
<!--                <li>The most reputable name in casino gaming offers safe, regulated, and entirely legal online gambling.</li>-->
<!--                <li>Discover the top real money casino games, including video poker, progressive slots, and live dealer table games.</li>-->
<!--                <li>Simple and simple to play, so you can start playing with just one button click.</li>-->
<!--                </ol></p>-->
<!--                <p>Are you interested in playing the finest slots? Then sign up for our casino right away and play at the best, most reputable gaming site.</p>-->
<!--                <p>Indian Premier League | Bangladesh Live Casino | cricket betting site Bangladesh</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2">অনলাইন স্লট বাংলাদেশ | অনলাইন স্লট ক্যাসিনো বাংলাদেশ</h1>-->
<!--                <br/>-->
<!--                <h2>{{ currentYear }} সালে বাংলাদেশ অনলাইন স্লট খেলা উপভোগ করুন</h2>-->
<!--                <p>সেরা অনলাইন স্লট বাংলাদেশ krikya.group ক্যাসিনো। আপনি আমাদের সাইটে সবচেয়ে রোমাঞ্চকর ক্যাসিনো স্লট গেম খেলতে পারেন।</p>-->
<!--                <p>ক্যাসিনোতে বিখ্যাত অনলাইন স্লট খেলুন বাংলাদেশের খেলোয়াড়রা আমাদের বিস্তৃত নির্বাচন ব্রাউজ করতে পারেন অনলাইন স্লট ক্যাসিনো বাংলাদেশ গেম আপনার উপযুক্ত সবচেয়ে উত্তেজনাপূর্ণ স্লট মেশিনগুলি এখানে পাওয়া যাবে।</p>-->
<!--                <h3>মার্জিত অনলাইন স্লট বোনাস জয়</h3>-->
<!--                <p>আপনি krikya.group অনলাইন ক্যাসিনোতে উত্তেজনাপূর্ণ স্লট বোনাস সহ উত্তম অনলাইন প্রচারমূলক পুরস্কার পেতে পারেন। আপনি এখানে সমস্ত গেম খেলতে পারেন krikya casino কোনো ঝুঁকি ছাড়াই.</p>-->
<!--                <p>আমরা নিশ্চিত করি যে আপনি যখন আমাদের অনলাইন ক্যাসিনো গেমিং সাইটে খেলবেন, আপনি সবচেয়ে নিরাপদ ক্যাসিনো গেমগুলিতে নিযুক্ত হবেন এবং কোনো প্রতারণার শিকার হবেন না।</p>-->
<!--                <h3>কেন krikya.group?</h3>-->
<!--                <p><ol>-->
<!--                <li>ক্যাসিনো গেমিংয়ের সবচেয়ে সম্মানজনক নাম নিরাপদ, নিয়ন্ত্রিত এবং সম্পূর্ণ আইনি অনলাইন জুয়া অফার করে।</li>-->
<!--                <li>ভিডিও জুজু, প্রগতিশীল স্লট এবং লাইভ ডিলার টেবিল গেম সহ শীর্ষ আসল অর্থের ক্যাসিনো গেমগুলি আবিষ্কার করুন৷</li>-->
<!--                <li>খেলার জন্য সহজ এবং সহজ, তাই আপনি শুধুমাত্র একটি বোতাম ক্লিক করে খেলা শুরু করতে পারেন।.</li>-->
<!--                </ol></p>-->
<!--                <p>আপনি কি সেরা স্লট খেলতে আগ্রহী? তারপর এখনই আমাদের ক্যাসিনোতে সাইন আপ করুন এবং সেরা, সবচেয়ে সম্মানজনক গেমিং সাইটে খেলুন।</p>-->
<!--                <p>ইন্ডিয়ান প্রিমিয়ার লিগ | বাংলাদেশ লাইভ ক্যাসিনো | ক্রিকেট বেটিং সাইট বাংলাদেশ</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-row v-else-if="$route.name == routeName.SPORTSBOOK">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">Krikya: Online Sports Bet & Win Today!</h1>-->
<!--                <br/>-->
<!--                <p>Sports bet are a popular choice of every casino player. To make your choice exciting, krikya.group brings you the best online sports betting. Using your phone, you can place sports bets quickly and easily. Unlike other sports betting apps, krikya.group lets you bet against the house, create your own Bti sports, or play with friends!</p>-->
<!--                <p>Experience best online betting sites Bangladesh!</p>-->
<!--                <h2>krikya.group: Deposit funds instantly</h2>-->
<!--                <p>Use currencies or fiat to deposit funds instantly to play Ibc sports. Deposit fees and long wait times are not an issue at Krikya Sportsbook.</p>-->
<!--                <h3>krikya.group: More Choice & Control</h3>-->
<!--                <p>Whether you find a cool online sports betting or create your own, you can bet any way you want.</p >-->
<!--                <p>Play with cash or virtual casino money. You always have the upper hand.</p>-->
<!--                <h3>krikya.group: top Rewards & Bonuses</h3>-->
<!--                <p>When you wager using betting tokens, you can avoid paying betting fees and get cash back bonuses, referral incentives, and other fantastic online casino gaming benefits.</p>-->
<!--                <h3>How do you create a krikya.group profile?</h3>-->
<!--                <p>Making an account is incredibly simple at best online betting site in Bangladesh! Then, fill out the registration form with your email address and go through the identity and age verification process.</p>-->
<!--                <p>Indian Premier League | Live Casino Bangladesh | Bangladesh Cricket Betting | Slot Game Bangladesh</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2">ক্রীড়া: ক্রীড়া বাজি এবং আজ জয়!</h1>-->
<!--                <br/>-->
<!--                <p> ক্রীড়া বাজি প্রতিটি ক্যাসিনো প্লেয়ার একটি জনপ্রিয় পছন্দ. আপনার পছন্দকে উত্তেজনাপূর্ণ করতে, krikya.group আপনার জন্য সেরা নিয়ে এসেছে-->
<!--                 অনলাইন খেলা বাজি. আপনার ফোন ব্যবহার করে, আপনি দ্রুত এবং সহজে ক্রীড়া বাজি রাখতে করতে পারেন। অন্যান্য স্পোর্টস বেটিং অ্যাপের মত নয়, krikya.group আপনাকে বাড়ির বিরুদ্ধে বাজি ধরতে দেয়, আপনার নিজের তৈরি Bti স্পোর্টস, বা বন্ধুদের সাথে খেলা!</p>-->
<!--                <p>অভিজ্ঞতা বাংলাদেশের সেরা অনলাইন বেটিং সাইটগুলি!</p>-->
<!--                <h2>krikya.group: তাৎক্ষণিকভাবে তহবিল জমা করুন</h2>-->
<!--                <p>খেলতে অবিলম্বে তহবিল জমা করতে মুদ্রা বা ফিয়াট ব্যবহার করুন Ibc স্পোর্টস. ডিপোজিট ফি এবং দীর্ঘ অপেক্ষার সময় কোন সমস্যা নয় Krikya Sportsbook.</p>-->
<!--                <h3>krikya.group: আরো পছন্দ ও নিয়ন্ত্রণ</h3>-->
<!--                <p>আপনি একটি কুল খুঁজে কিনা অনলাইন খেলা বাজি বা আপনার নিজের তৈরি করুন, আপনি যে কোনো উপায়ে বাজি ধরতে পারেন।</p>-->
<!--                <p>নগদ বা ভার্চুয়াল ক্যাসিনো অর্থ দিয়ে খেলুন। আপনি সবসময় উপরের হাত আছে.</p>-->
<!--                <h3>krikya.group: শীর্ষ পুরস্কার এবং বোনাস</h3>-->
<!--                <p>যখন আপনি বাজি টোকেন ব্যবহার করে বাজি ধরবেন, আপনি বেটিং ফি প্রদান এড়াতে পারেন এবং নগদ ফেরত বোনাস পেতে পারেন, রেফারেল প্রণোদনা, এবং অন্যান্য চমত্কার অনলাইন ক্যাসিনো গেমিং সুবিধা।</p>-->
<!--                <h3>আপনি কিভাবে একটি krikya.group প্রোফাইল তৈরি করবেন?</h3>-->
<!--                <p>একটি অ্যাকাউন্ট তৈরি করা অবিশ্বাস্যভাবে সহজ বাংলাদেশের সেরা অনলাইন বেটিং সাইট ! তারপর, আপনার ইমেল ঠিকানা সহ নিবন্ধন ফর্মটি পূরণ করুন এবং পরিচয় এবং বয়স যাচাইকরণ প্রক্রিয়ার মধ্য দিয়ে যান৷ </p>-->
<!--                <p>ইন্ডিয়ান প্রিমিয়ার লিগ | লাইভ ক্যাসিনো বাংলাদেশ | বাংলাদেশ ক্রিকেট বেটিং | স্লট গেম বাংলাদেশ</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
<!--        <v-row v-else-if="$route.name == routeName.TABLE_GAMES">-->
<!--            <v-col v-if="i18n.locale == 'en'">-->
<!--                <h1 class="h2">krikya.group Casino The Top Online Table Games in Bangladesh</h1>-->
<!--                <br/>-->
<!--                <p>Check out krikya.group's full collection of the top online table games in Bangladesh, including online Blackjack, baccarat game, rummy, and much more. You will find all the action-packed casino games from all the top game providers on this list. This is where you can stop your search for card games or roulette.</p>-->
<!--                <h2>Play Safe Table casino games</h2>-->
<!--                <p> At krikya.group, you are playing safe table games. Using just a few clicks or taps, krikya.group's table and card game collection include some of the most impressive titles. So take a chance on these examples and play table and card games online, just the way you like it. Simply choose your favorite game and click on 'Play Now' to get started.</p>-->
<!--                <p>With our partnerships with multiple providers, we offer our customers quantity and quality of live casino games. The following are some of the most popular live casino games play:</p>-->
<!--                <h3>Most Exciting krikya.group Blackjack</h3>-->
<!--                <p>Two or more cards are used in this game. Bets will be won by parties whose card values are closest to 21. Casino Bets exceeding 21 will lose. So, join us today to play the leading Blackjack</p>-->
<!--                <h4>Win Big Rewards Playing Baccarat</h4>-->
<!--                <p>Players will need to place a side bet when playing Live Baccarat, just like they do when playing Traditional Baccarat.</p>-->
<!--                <p>Indian Premier League | Live Casino Bangladesh | Bangladesh Cricket Betting | Slot Game Bangladesh</p>-->
<!--            </v-col>-->
<!--            <v-col v-else-if="i18n.locale == 'bd'">-->
<!--                <h1 class="h2">krikya.group ক্যাসিনো বাংলাদেশের শীর্ষ অনলাইন টেবিল গেম</h1>-->
<!--                <br/>-->
<!--                <p>চেক আউট krikya.group's full collection of the বাংলাদেশের শীর্ষ অনলাইন টেবিল গেম, including অনলাইন Blackjack , ব্যাকারেট খেলা , রামি , এবং আরো অনেক কিছু. আপনি এই তালিকায় সমস্ত শীর্ষস্থানীয় গেম সরবরাহকারীদের থেকে সমস্ত অ্যাকশন-প্যাকড ক্যাসিনো গেমগুলি পাবেন৷এখানে আপনি কার্ড গেম বা রুলেটের জন্য আপনার অনুসন্ধান বন্ধ করতে পারেন.</p>-->
<!--                <h2>সেফ টেবিল ক্যাসিনো গেম খেলুন</h2>-->
<!--                <p> krikya.group এ, আপনি নিরাপদ টেবিল গেম খেলছেন।মাত্র কয়েকটি ক্লিক বা ট্যাপ ব্যবহার করে, krikya.group-এর টেবিল এবং কার্ড গেমের সংগ্রহে সবচেয়ে চিত্তাকর্ষক শিরোনাম রয়েছে।তাই এই উদাহরণগুলিতে একটি সুযোগ নিন এবং অনলাইনে টেবিল এবং কার্ড গেম খেলুন, ঠিক যেভাবে আপনি এটি পছন্দ করেন।সহজভাবে আপনার প্রিয় গেমটি বেছে নিন এবং শুরু করতে 'এখনই খেলুন'-এ ক্লিক করুন.</p>-->
<!--                <p>একাধিক প্রদানকারীর সাথে আমাদের অংশীদারিত্বের সাথে, আমরা আমাদের গ্রাহকদের লাইভ ক্যাসিনো গেমের পরিমাণ এবং গুণমান অফার করি।নিচের কয়েকটি জনপ্রিয় লাইভ ক্যাসিনো গেম খেলা:</p>-->
<!--                <h3>সবচেয়ে উত্তেজনাপূর্ণ krikya.group Blackjack</h3>-->
<!--                <p>এই গেমটিতে দুই বা ততোধিক কার্ড ব্যবহার করা হয়।যে দলগুলির কার্ডের মান 21-এর কাছাকাছি, সেই দলগুলি বাজি জিতবে৷ 21-এর বেশি ক্যাসিনো বেটগুলি হেরে যাবে৷তাই, নেতৃস্থানীয় Blackjack খেলার জন্য আজ আমাদের সাথে যোগ দিন</p>-->
<!--                <h4>Baccarat খেলে বড় পুরস্কার জিতুন</h4>-->
<!--                <p>লাইভ ব্যাকার্যাট খেলার সময় খেলোয়াড়দের একটি সাইড বাজি রাখতে হবে, ঠিক যেমন তারা ঐতিহ্যগত ব্যাকার্যাট খেলার সময় করে.</p>-->
<!--                <p>ইন্ডিয়ানপ্রিমিয়ারলিগ | লাইভ ক্যাসিনো বাংলাদেশ | বাংলাদেশ ক্রিকেট বেটিং | স্লট গেম বাংলাদেশ</p>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-row>
</template>

<script>
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import i18n from '@/plugins/i18n'

export default {
  name: 'footerContent',
  data: () => ({
    panel:0,
    routeName: ROUTE_NAME,
    hasContent: false,
    i18n,
    currentYear :new Date().getFullYear()
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
        this.hasContent = [this.routeName.HOME, this.routeName.CASINO, this.routeName.VIP, this.routeName.CRICKET, this.routeName.LOTTERY, this.routeName.PROMOTION, this.routeName.SLOT, this.routeName.SPORTSBOOK, this.routeName.TABLE_GAMES].includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss">
.footer-expansion-panel{
  background-color: inherit !important;
}
    .h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 1.3;
        text-align: justify;
    }
</style>