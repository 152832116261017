<template>
  <div>
    <v-row no-gutters v-if="!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK" class="download-bar">
<!--        <v-icon @click="hideDownloadBar()" class="mr-2">mdi-close</v-icon>-->
      <img class="mr-2" width="48" height="48" src="/static/image/layout/download-app.png" alt="download" />
      <div>
        <v-col class="px-0 py-1">
          <label>{{ $t(`label.downloadApp`) }}</label>
        </v-col>
        <div class="download-button-container">
          <app-button :title="$t(`button.download`)" :action="downloadAPK" :customClass="`download-bar-button`"></app-button>
          <app-button :title="$t(`button.watchVideo`)" :action="watchVideo" :customClass="`watch-video-bar-button`"></app-button>
        </div>
      </div>
      <v-icon class="download-app-close" @click="hideDownloadBar()">mdi-close</v-icon>
    </v-row>
    <v-toolbar
      dense
      :class="`full-width header-top elevation-3 ${!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK ? 'download-bar-height' : ''}`"
      :style="$vuetify.breakpoint.mdAndUp ? 'height: 70px; ' : 'height: 45px'"
    >
      <v-row v-if="$vuetify.breakpoint.smAndDown" class="mobile">
        <v-col class="d-flex flex-grow-0 align-center" cols="2">
          <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" height="18" width="22"></v-app-bar-nav-icon>
        </v-col>

        <v-col class="d-flex justify-space-around pt-2">
          <router-link :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }" class="d-flex justify-center align-center">
            <img width="120px" height="30" src="/static/image/home/logo.webp" alt="krikya logo" />
          </router-link>
        </v-col>

        <v-col class="d-flex align-center mobile-header-rightBtns" cols="2">
          <v-btn icon color="white" v-if="isLoggedIn && this.isDAndroid" :plain="true" @click="downloadAPK">
            <img src="/static/image/home/apkDownload.png" width="32" height="28" :contain="true" />
          </v-btn>
          <v-btn icon color="white" :plain="true" @click="showIntercomLC">
            <img src="/static/svg/layout/tr_livechat.svg" width="32" height="32" :contain="true" />
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="header-top-desktop">
        <v-row class="col" no-gutters>
          <v-row class="self-align" no-gutters justify="start">
            <router-link :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }" class="d-flex justiy-center">
              <img width="195px" height="50" src="/static/image/home/logo.webp" alt="krikya logo" />
            </router-link>
          </v-row>

          <v-row no-gutters justify="end" class="header-column">
            <div v-if="isLoggedIn" class="is-login-header">
              <img class="btn-icon" width="28px" height="28px" src="/static/image/icon/icon-profile.svg" alt="profile" @click="goToProfilePage" />
              <v-badge dot right offset-x="8" offset-y="8" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
                <img class="btn-icon" width="28px" height="28px" src="/static/image/icon/icon-inbox.svg" alt="inbox" @click="goToInboxPage" />
              </v-badge>
              <div class="header-divider"></div>
              <label>{{ $t(`label.mainWallet`) }}</label>
              <label class="text-capitalize balance" v-if="notLoading">
                <span v-if="showBalance">{{ memberWallet.totalBalance | currency(currentCurrency) | maskNumber }}</span>
                <span v-else>{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
              </label>
              <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
              <v-icon size="20" color="var(--v-secondary-base)" @click="getMemberWallet()" class="refresh-balance">
                mdi-refresh
              </v-icon>
              <button-primary :action="() => goToDepositPage()" :title="$t('label.deposit')"></button-primary>
              <div @click="logout">
                <label class="invisible-button">{{ $t(`label.logout`) }}</label>
              </div>
            </div>
            <app-login-embedded
              v-if="!isLoggedIn"
              :openRegisterDialog="this.openRegisterDialog"
              :openLoginDialog="this.openLoginDialog"
              :openLanguageDialog="this.openLanguageDialog"
            ></app-login-embedded>

            <v-btn @click="openLanguageDialog()" class="text-capitalize align-self-center white--text invisible-button" :plain="true">
              <v-avatar size="24" class="language-button">
                <img width="24" height="24" :src="`/static/image/country/${currentCurrency}.svg`" alt="krikya exchange" />
              </v-avatar>
              <label>{{ languageSelected }} {{ $t(`language.${currentLanguage}`) }}</label>
            </v-btn>
          </v-row>
        </v-row>
      </v-row>
    </v-toolbar>

    <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" class="side-navigation-drawer" v-model="drawer" fixed left temporary width="60%">
      <v-list nav class="mobile_list_menu">
        <v-list-item-group active-class="">
          <v-list-item class="justify-content side-nav-logo" @click="openLanguageDialog()" style="display: flex;">
            <v-container>
              <v-row justify="space-between">
                <v-col cols="4">
                  <div>
                    <img class="customwh" width="120px" height="30" src="/static/image/home/logo.webp" />
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex flex-row align-center " style="justify-content: right;">
                    <div class="">
                      <v-btn icon small>
                        <v-avatar size="25" class="language-button">
                          <img width="25" height="25" :src="`/static/image/country/${currentCurrency}.svg`" alt="krikya cricket exchange" />
                        </v-avatar>
                      </v-btn>
                    </div>
                    <div no-gutters class="d-flex flex-column justify-center align-center" style="justify-content: right;">
                      <span class="padding-l">{{ languageList[0].currencyCd }} {{ languageList[0].currency }}</span>
                      <!--              <span  tyle=" font-weight: bolder; font-size: 12px; padding-left: 10px;">-->

                      <!--                     {{ $t(`language.${currentLanguage}`) }}-->
                      <!--            </span>-->
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <!--            <v-list-item @click="openLanguageDialog()">-->
            <!--              <v-row no-gutters class="list-item-container">-->
            <!--                <v-list-item-icon :class="['side-menu-icon-wrapper ml-1', isLoggedIn ? 'mt-0' : 'mt-3']">-->
            <!--                  <v-avatar size="30" class="language-button">-->
            <!--                    <img width="30" height="30" :src="`/static/image/country/${currentCurrency}.svg`"-->
            <!--                         alt="krikya cricket exchange"/>-->
            <!--                  </v-avatar>-->
            <!--                </v-list-item-icon>-->
            <!--                <v-list-item-content :class="['pl-1', isLoggedIn ? 'pt-0' : '']">-->
            <!--                  <v-list-item-title>{{ $t(`label.language`) }}</v-list-item-title>-->
            <!--                </v-list-item-content>-->
            <!--              </v-row>-->
            <!--            </v-list-item> d-flex flex-column justify-end align-center-->
          </v-list-item>
          <v-list-item :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }" exact>
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/home.png`" alt="krikya cricket" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.home`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item :to="{ name: routeName.PROMOTION, params: { lang: this.$route.params.lang } }" class="list-item-standalone">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/promo.png`" alt="krikya promotion" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.promotion`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item :to="{ name: routeName.VIP, params: { lang: this.$route.params.lang } }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon>
                <img :src="`/static/svg/mobileMenu/krikbet.png`" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters justify="start" align="center">
                    <v-col cols="auto" class="mt-1 breakWord-onspace">{{ $t(`label.bettingPassNavName`) }}</v-col>
                    <!--                    <div class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px;width:30px">-->
                    <!--                      <span class="font-weight-bold">{{ $t(`label.new`) }}</span>-->
                    <!--                    </div>-->
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item v-if="configuration.reward_store.enabled === true" :to="{ name: routeName.REWARDSTORE, params: { lang: this.$route.params.lang } }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon>
                <img :src="`/static/image/profile/rewardStore_icon.png`" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters justify="start" align="center">
                    <v-col cols="9" class="mt-1 breakWord-onspace">{{ $t(`label.rewardStore`) }}</v-col>
                    <v-col cols="2" class="justify-end">
                      <div class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px;width:30px">
                        <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item :to="{ name: routeName.VIPTIER, params: { lang: this.$route.params.lang } }" class="list-item-standalone">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img src="/static/svg/mobileMenu/viptier.png" alt="krikya viptier" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.viptier`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-divider class="my-3 mx-3" horizontal></v-divider>
          <v-row no-gutters class="side-navigation-text">
            {{ $t(`label.partnerProgram`) }}
          </v-row>
          <v-list-item :to="{ name: routeName.REFERRAL_SUMMARY, params: { lang: this.$route.params.lang } }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/referral.png`" alt="krikya referral" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.referral`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <v-list-item :to="{ name: routeName.APPLY_AGENT, params: { lang: this.$route.params.lang } }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/affiliate.png`" alt="krikya referral" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters justify="start" align="center">
                    <v-col cols="auto" class="mt-1 breakWord-onspace">{{ $t(`label.affiliate`) }}</v-col>
                    <!--                    <div class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px;width:30px">-->
                    <!--                      <span class="font-weight-bold">{{ $t(`label.new`) }}</span>-->
                    <!--                    </div>-->
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-divider class="my-3 mx-3" horizontal></v-divider>
          <v-row no-gutters class="side-navigation-text">
            {{ $t(`label.games`) }}
          </v-row>
          <v-list-item v-for="gameType in storageGameProviderType" :key="gameType.type" :to="getListItemTo(gameType)" exact>
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/${gameType.type}.png`" class="side-menu-icon" :alt="`${gameType.type}`" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.${gameType.type}`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-divider class="my-3 mx-3" horizontal></v-divider>
          <v-row no-gutters class="side-navigation-text">
            {{ $t(`label.others`) }}
          </v-row>
          <!--          <v-list-item class="sidenav icon" style="width: 200px"> &lt;!&ndash;hotgame&ndash;&gt;-->
          <!--            <v-col>-->
          <!--              <v-row class="align-content-center" style="display: block">-->
          <!--                <v-list-item-icon class="align-center">-->
          <!--                  <img src="/static/svg/mobileMenu/Hot.svg" height="32px" width="30px"/>-->
          <!--                </v-list-item-icon>-->
          <!--              </v-row>-->
          <!--              <v-row>-->
          <!--                <v-list-item-content class="text-center"  style="display: table">-->
          <!--                  <v-list-item-title style="font-size: 14px">{{ $t(`label.hotGames`) }}</v-list-item-title>-->
          <!--                </v-list-item-content>-->
          <!--              </v-row>-->
          <!--            </v-col>-->
          <!--          </v-list-item>-->
          <!--          <v-btn icon color="white" :plain="true" @click="showIntercomLC">-->
          <!--            <img src="/static/svg/layout/tr_livechat.svg" width="32" height="32" :contain="true" />-->
          <!--          </v-btn>-->
          <v-list-item @click="showIntercomLC">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/liveChat.png`" alt="krikya livechat" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="breakWord-onspace">{{ $t(`label.liveChat`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item @click="downloadAPK" v-if="this.isDAndroid">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/apkDownload.png`" alt="krikya apk" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="breakWord-onspace">{{ $t(`label.download`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item @click="logout" v-if="isLoggedIn">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon>
                <img :src="`/static/svg/mobileMenu/logout.svg`" alt="krikya crazytime exchange" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.logout`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <!--          <v-list-item @click="openLanguageDialog()">-->
          <!--            <v-row no-gutters class="list-item-container">-->
          <!--              <v-list-item-icon :class="['side-menu-icon-wrapper ml-1', isLoggedIn ? 'mt-0' : 'mt-3']">-->
          <!--                <v-avatar size="30" class="language-button">-->
          <!--                  <img width="30" height="30" :src="`/static/image/country/${currentCurrency}.svg`"-->
          <!--                       alt="krikya cricket exchange"/>-->
          <!--                </v-avatar>-->
          <!--              </v-list-item-icon>-->
          <!--              <v-list-item-content :class="['pl-1', isLoggedIn ? 'pt-0' : '']">-->
          <!--                <v-list-item-title>{{ $t(`label.language`) }}</v-list-item-title>-->
          <!--              </v-list-item-content>-->
          <!--            </v-row>-->
          <!--          </v-list-item>-->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <app-page-navigator></app-page-navigator>

    <div :class="`body ${!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK ? 'download-bar-height-body' : ''}`">
      <router-view></router-view>
      <app-footer></app-footer>
    </div>

    <div v-if="$vuetify.breakpoint.smAndDown" class="bottom-navigator">
      <v-row no-gutters v-if="!isLoggedIn" class="bottom-navigator-before-login">
        <v-col cols="4" @click="openLanguageDialog()" class="bottom-navigator-before-login-first d-flex flex-row justify-center align-center no-gutters">
          <div class="d-flex flex-column justify-center align-center">
            <v-btn icon small>
              <v-avatar size="25" class="language-button">
                <img width="25" height="25" :src="`/static/image/country/${currentCurrency}.svg`" alt="krikya cricket exchange" />
              </v-avatar>
            </v-btn>
          </div>
          <div no-gutters class="d-flex flex-column justify-center align-center">
            <span style="font-weight: bolder; font-size: 12px; padding-left: 10px;">{{ languageList[0].currencyCd }} {{ languageList[0].currency }}</span>
            <span style=" font-weight: bolder; font-size: 12px; padding-left: 10px;">
              {{ $t(`language.${currentLanguage}`) }}
            </span>
          </div>
        </v-col>
        <v-col cols="4" @click="openLoginDialog()" class="bottom-navigator-before-login-third d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.login`) }}</b>
        </v-col>
        <v-col cols="4" @click="openRegister" class="bottom-navigator-before-login-second d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.register`) }}</b>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="isLoggedIn">
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.HOME, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-home.svg`" alt="home" />
              <p>{{ $t(`label.home`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.PROMOTION, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-promotion.svg`" alt="promotion" />
              <p>{{ $t(`label.promotion`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <router-link class="bottom-navigation-center-button" :to="{ name: routeName.FUNDS, params: { lang: this.$route.params.lang } }" style="line-height: .5;">
            <img class="icon_btm_nav depo_icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-deposit.svg`" alt="deposit" />
            <div>{{ $t(`label.deposit`) }}</div>
          </router-link>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.INBOX, params: { lang: this.$route.params.lang } }">
            <div class="text-center">
              <v-badge dot right offset-x="8" offset-y="14" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
                <img class="icon_btm_nav" src="/static/image/icon/icon-inbox.svg" alt="inbox" />
              </v-badge>
              <p>{{ $t(`button.inbox`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" to="#" @click="openProfileSheet">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-mine.svg`" alt="krikya crazytime exchange" />
              <p>{{ $t(`label.profile`) }}</p>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <profile-sheet :showProfileSheet="showProfileSheet" :closeProfileSheet="closeProfileSheet" :logout="logout"></profile-sheet>

    <!--    <app-floating-reward></app-floating-reward>-->
    <app-floating-free-coin></app-floating-free-coin>
    <app-floating-contactUs></app-floating-contactUs>

    <!-- WAIT REGISTER DIALOG -->
    <app-dialog :dialogShow="waitRegisterDialogShow" :max-width="600" :title="$t(`label.wait`)" :closeAction="closeWaitRegisterDialog">
      <app-wait-register :openRegisterDialog="openRegisterDialog" :openLoginDialog="openLoginDialog"></app-wait-register>
    </app-dialog>

    <!-- REGISTER DIALOG -->
    <app-dialog
      :dialogShow="registerDialogShow"
      :max-width="400"
      :min-height="724"
      :title="$t(`label.signUp`)"
      :isAuth="false"
      :header_title="'register'"
      :openLoginDialog="openLoginDialog"
      :closeAction="this.closeRegisterDialog"
    >
      <app-register :openLoginDialog="this.openLoginDialog" :registerErrorMsg="this.registerErrorMsg"></app-register>
    </app-dialog>

    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.forgotPassword`).slice(0, -1)" :closeAction="this.closeForgotPasswordDialog" :customClass="'pa-0'">
      <app-forgot-password :openRegisterDialog="openRegisterDialog" :openLoginDialog="this.openLoginDialog"></app-forgot-password>
    </app-dialog>

    <!-- RESET PASSWORD DIALOG -->
    <app-dialog :dialogShow="resetPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeResetPasswordDialog">
      <app-reset-password :resetPasswordMemberCode="resetPasswordObj.memberCode" :resetPasswordContact="resetPasswordObj.contact"></app-reset-password>
    </app-dialog>

    <!-- LOGIN DIALOG -->
    <app-dialog
      :dialogShow="loginDialogShow"
      ref="loginDialog"
      :max-width="400"
      :title="$t(`label.welcomeToBrand`)"
      :isAuth="false"
      :header_title="'login'"
      :openRegisterDialog="openRegisterDialog"
      :closeAction="this.closeLoginDialog"
      :customClass="'pa-0'"
    >
      <app-login
        :openRegisterDialog="this.openRegisterDialog"
        :loginErrorMsg="this.loginErrorMsg"
        :openForgotPasswordDialog="this.openForgotPasswordDialog"
        :closeAction="this.closeLoginDialog"
      ></app-login>
    </app-dialog>

    <!-- REGISTER SUCCESS DIALOG -->
    <app-dialog
      :dialogShow="registerSuccessDialogShow"
      :isRegSuccess="true"
      ref="registerSuccessDialog"
      :max-width="400"
      :title="$t(`label.registerSuccess`)"
      :closeAction="this.closeRegisterSuccessDialog"
    >
      <app-register-success :closeRegisterSuccessDialog="closeRegisterSuccessDialog"></app-register-success>
    </app-dialog>

    <!--PT Quick Transfer Dialog-->
    <app-dialog :dialogShow="quickTransferDialogShow" :max-width="450" :title="this.transferMessage" :closeAction="this.closeQuickTransferDialog">
      <app-transfer-component :isNonBanking="true" :provider="this.transferToProvider" :ptGameInfo="this.ptGameInfo"></app-transfer-component>
    </app-dialog>

    <!-- PAGE DIALOG -->
    <app-dialog :dialogShow="pageDialogShow" :max-width="400" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog
        :dialogMessageTitle="pageDialog.messageTitle"
        :dialogMessage="pageDialog.message"
        :dialogButton="pageDialog.button"
        :dialog-attachments="pageDialog.attachment"
      ></app-page-dialog>
    </app-dialog>

    <!-- INSTANT POPUP DIALOG -->
    <instant-popup-dialog :dialog-show="instantDialogShow" :max-width="438" :closeAction="this.closeInstantPopup" :array-info="popupAnnouncement"></instant-popup-dialog>

    <!-- COIN TIPS POPUP DIALOG -->
    <coin-tips-pop-up-dialog :dialog-show="coinTipsDialog" :max-width="440" :closeAction="this.closecoinTipsDialog"></coin-tips-pop-up-dialog>

    <instant-login-popup-dialog :dialog-show="instantLoginDialogShow" :max-width="438" :closeAction="this.closeInstantLoginPopup" :array-info="loginPopupAnnouncement"></instant-login-popup-dialog>

    <!-- LANGUAGE DIALOG -->
    <app-dialog
      :dialogShow="languageDialogShow"
      ref="languageDialog"
      :max-width="300"
      :title="$t(`label.currencyLanguage`)"
      :isAuth="false"
      :header_title="'login'"
      :closeAction="this.closeLanguageDialog"
      :customClass="'pa-0'"
    >
      <app-language :closeAction="this.closeLanguageDialog"></app-language>
    </app-dialog>

    <!--    <app-dialog :dialogShow="coinTipsDialog" :title="$t(`label.coinTipsTitle`)" :closeAction="this.closecoinTipsDialog">-->
    <!--      <app-coin-tips :closeAction="this.closecoinTipsDialog"></app-coin-tips>-->
    <!--    </app-dialog>-->
    <app-loading-progress ref="loading"></app-loading-progress>
  </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import moment from 'moment'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import AccountLiveChat from '@/assets/svg/liveChat.svg'
import AppTransferComponent from '@/views/bank/transfer'
import AccountProfile from '@/assets/svg/account-profile.svg'
import AccountWallet from '@/assets/svg/account-deposit.svg'
import AccountWithdrawal from '@/assets/svg/account-withdrawal.svg'
import AccountHistory from '@/assets/svg/account-history.svg'
import AppFloatingFreeCoin from '@/components/home/FloatingFreeCoin.vue'
import AppFloatingContactUs from '@/components/home/FloatingContactUs.vue'
import NavTransfer from '@/assets/svg/transfer.svg'
import NavDeposit from '@/assets/svg/deposit.svg'
import NavWithdrawal from '@/assets/svg/withdrawal.svg'
import NavLogout from '@/assets/svg/logout.svg'
import vipIcon from '@/assets/svg/vip.svg'
import InstantPopupDialog from '@/components/InstantPopupDialog.vue'
import CoinTipsPopUpDialog from '@/components/coinTipsPopUp.vue'

import { AnnouncementType, DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppRegister from '@/components/member/register.vue'
import AppRegisterSuccess from '@/components/member/registerSuccess.vue'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import AppWaitRegister from '@/components/member/waitRegister.vue'
import AppForgotPassword from '@/components/member/forgotPassword.vue'
import AppResetPassword from '@/components/member/resetPassword.vue'
import AppPageDialog from '@/components/layout/PageDialog.vue'
import AppPageNavigator from '@/components/layout/PageNavigator.vue'
import AppFooter from '@/components/layout/Footer.vue'
import AppLoadingProgress from '@/components/layout/LoadingProgress.vue'
import AppLogin from '@/components/member/login.vue'
import AppLanguage from '@/components/layout/Language.vue'

import { SESSION, SHARED, CACHE_KEY } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import {
  MEMBER_RESET_LOGIN_STORE,
  MEMBER_LOGOUT,
  MEMBER_REFRESH_TOKEN,
  MEMBER_RESET_LOGOUT_STORE,
  MEMBER_CHECK_TOKEN_VALIDITY,
  MEMBER_WALLET,
  MEMBER_RESET_FORGOT_PASSWORD_STORE,
  MEMBER_RESET_RESET_PASSWORD_STORE,
  MEMBER_CONTACT_VERIFICATION,
  MEMBER_RESET_CONTACT_VERIFICATION_STORE,
  MEMBER_RESET_REGISTER_STORE,
  MEMBER_GET_VIP_PROGRESS,
  MEMBER_RESET_SMS_VERIFICATION,
  ONE_SIGNAL_PLAYER_IDS,
  ONE_SIGNAL_PLAYER_IDS_RESET_STORE,
  MEMBER_RESET_UPDATE_FORGOT_PASSWORD
} from '@/store/member.module'
import { LOGIN_POPUP_LIST, POPUP_LIST } from '@/store/announcement.module'
import { WHEEL_MODULE } from '@/store/wheel.module'
import { INBOX_GET_MSG } from '@/store/inbox.module'
import ButtonPrimary from '../ButtonPrimary.vue'
import InstantLoginPopupDialog from '@/components/InstantLoginPopupDialog'
import OneSignalVue from 'onesignal-vue'

export default {
  name: 'layoutMaster',
  components: {
    currency: SHARED.DEFAULT_CURRENCY,
    InstantLoginPopupDialog,
    AccountLiveChat,
    AppTransferComponent,
    AccountProfile,
    AccountWallet,
    AccountWithdrawal,
    AccountHistory,
    AppLogin,
    AppLanguage,
    AppRegister,
    AppRegisterSuccess,
    AppLoginEmbedded,
    AppForgotPassword,
    AppResetPassword,
    AppPageDialog,
    AppPageNavigator,
    AppFooter,
    AppLoadingProgress,
    AppWaitRegister,
    AppFloatingFreeCoin,
    AppFloatingContactUs,
    NavDeposit,
    NavWithdrawal,
    NavLogout,
    NavTransfer,
    vipIcon,
    InstantPopupDialog,
    ButtonPrimary,
    CoinTipsPopUpDialog
  },
  data: () => ({
    showBalance: true,
    coinTipsDialog: false,
    playerIds: '',
    showDownloadAPK: true,
    isIos: uiHelper.IsIos(),
    isDAndroid: uiHelper.isAndroid(),
    showProfileSheet: false,
    time: '',
    instantDialogShow: false,
    instantLoginDialogShow: false,
    instantPopupAnnouncement: [],
    drawer: false,
    notLoading: true,
    currentLanguage: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    transferMessage: locale.getMessage(`label.ptQuickTransfer`, ['PT']),
    isTransferWalletLoginDialog: false,
    ptGameInfo: null,
    transferToProvider: 'PT',
    devicePlatform: DevicePlatform,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    uihelper: uiHelper,
    quickTransferDialogShow: false,
    registerSuccessDialogShow: false,
    waitRegisterDialogShow: false,
    registerDialogShow: false,
    loginDialogShow: false,
    forgotPasswordDialogShow: false,
    resetPasswordDialogShow: false,
    languageDialogShow: false,
    firstTimeRegister: true,
    routeName: ROUTE_NAME,
    resetPasswordObj: {
      contact: '',
      memberCode: ''
    },
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      message: [],
      button: []
    },
    memberLink: SHARED.MEMBER_HEADER_NAVIGATOR_LINK,
    loginErrorMsg: '',
    registerErrorMsg: '',
    language: [
      {
        title: 'English',
        cd: 'en'
      },
      {
        title: 'Bengali',
        cd: 'bd'
      }
    ],
    languageList: [
      {
        currency: 'BDT',
        currencyCd: '৳',
        img: 'BDT',
        language: [
          {
            title: 'English',
            cd: 'en'
          },
          {
            title: 'Bengali',
            cd: 'bd'
          }
        ]
      }
    ]
  }),
  computed: {
    configuration() {
      return this.$store.state.reward.configurationStatus
    },
    loginPopupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupLoginAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantLoginPopup()
      }
      console.log(p)
      return p
    },
    popupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantPopup()
      }
      return p
    },
    hasUnreadInboxMsg() {
      return this.$store.state.inbox.inboxMsg.filter(m => !m.has_read).length > 0
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    wof() {
      return this.$store.state.wheel.wheel
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    loginResponseComplete() {
      return this.$store.state.member.loginResponse.complete
    },
    verifyContactResponseComplete() {
      return this.$store.state.member.verifyContactResponse.complete
    },
    changeForgotPasswordResponse() {
      return this.$store.state.member.changeForgotPassword.complete
    },
    resetPasswordResponseComplete() {
      return this.$store.state.member.resetPasswordResponse.complete
    },
    registerResponseComplete() {
      return this.$store.state.member.registerResponse.complete
    },
    forgotPasswordResponseComplete() {
      return this.$store.state.member.forgotPasswordResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.member.logoutResponse.complete
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    vipLevel() {
      if (uiHelper.getCookie(SESSION.VIP) == null) {
        let m = this.$store.state.member.info
        let vip = m.group
        uiHelper.setCookie(SESSION.VIP, vip, 1)
        return vip
      }
      return uiHelper.getCookie(SESSION.VIP)
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipDepositProgress() {
      return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
    },
    vipRolloverProgress() {
      return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    languageSelected() {
      for (const lang of this.languageList) {
        if (lang.currency == this.currentCurrency) {
          // const language = lang.find(l => {
          //     // console.log('language', language);
          //     console.log('l', l);
          // });
          // const title = this.selectedLanguage === "en" ? title.en : title.bn;
          // return `${lang.currencyCd} ${lang.currency} ${title}`;
          return `${lang.currencyCd} ${lang.currency}`
        }
      }
      return null
    }
    // languageSelected() {
    //   for (const lang of this.languageList) {
    //     if (lang.currency === this.currentCurrency) {
    //       const selectedLanguage = lang.language.find(l => l.cd === this.selectedLanguage);
    //       if (selectedLanguage) {
    //         return `${lang.currencyCd} ${lang.currency} ${selectedLanguage.cd}`;
    //       } else {
    //         return `${lang.currencyCd} ${lang.currency}`;
    //       }
    //     }
    //   }
    //   return null;
    // },
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.getInboxMsg()
        this.getLoginPopupAnnouncement()
      }
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    memberInfo() {
      let m = this.$store.state.member.info
      let vip = m.group
      uiHelper.setCookie(SESSION.VIP, vip, 1)
      this.currentCurrency = uiHelper.getCurrency()

      // if(this.currentLanguage != m.preferredLanguage && !uiHelper.getCookie("changeLanguage")){
      //   uiHelper.removeCookie('language')
      //   uiHelper.setCookie('language', m.preferredLanguage)
      //   this.currentLanguage = m.preferredLanguage
      //   window.location.reload()
      // }
    },
    memberWallet() {
      this.notLoading = true
    },
    changeForgotPasswordResponse() {
      //new forgotpassword flow
      let response = this.$store.state.member.changeForgotPassword
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.updateForgotPasswordCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_FORGOT_PASSWORD}`)
      }
    },
    registerResponseComplete() {
      let response = this.$store.state.member.registerResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Register`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)

          if (response.success && response.membercode) {
            this.AdElementAfterRegistered(response.membercode)
          }
        } catch (err) {
          console.log(err)
        }
        this.registerResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_REGISTER_STORE}`)
      }
    },
    loginResponseComplete() {
      let response = this.$store.state.member.loginResponse
      /* Check Path Should Reload */
      if (this.$route.fullPath == '/sportsbook/bti') {
        window.location.reload()
      }
      if (response.complete) {
        try {
          this.$ga.event('Account', `Login`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
          this.AdElementLoginPixel()
        } catch (err) {
          console.log(err)
        }
        if (response.success) {
          this.setGoogleAnalyticUserId()
          this.oneSignal()
        }
        this.loginResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGIN_STORE}`)
        this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS_RESET_STORE}`)
      }
    },
    logoutResponseComplete() {
      let response = this.$store.state.member.logoutResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Logout`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.logoutResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGOUT_STORE}`)
      }
    },
    verifyContactResponseComplete() {
      let response = this.$store.state.member.verifyContactResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Verify Contact`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.verifyContactResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CONTACT_VERIFICATION_STORE}`)
      }
    },
    resetPasswordResponseComplete() {
      let response = this.$store.state.member.resetPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.resetPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_RESET_PASSWORD_STORE}`)
      }
    },
    forgotPasswordResponseComplete() {
      let response = this.$store.state.member.forgotPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Forgot Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.forgotPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_FORGOT_PASSWORD_STORE}`)
      }
    }
  },
  created() {
    this.checkHasSsoToken()
    this.initializePageLanguage()
    this.metaScript()
    this.initShowDownloadAPK()
    this.isAndroid()
    this.timeInterval()
    this.pageDialog = this.initializePageDialogProperty()
    if (localStorage.getItem(SESSION.TOKEN)) {
      this.getMemberVipProgress()
      this.validateMemberToken()
    } else {
      this.getPopupAnnouncement()
    }
    this.setGoogleAnalyticUserId()
    this.getInboxMsg()
    this.$eventHub.$on('open-page-dialog', dialog => {
      this.openPageDialog(dialog)
    })
    this.$eventHub.$on('close-page-dialog', unt => {
      this.closePageDialog()
    })
    this.$eventHub.$on('open-quick-transfer-dialog', gameInfo => {
      this.openQuickTransfer(gameInfo)
    })
    this.$eventHub.$on('close-quick-transfer-dialog', dialog => {
      this.closeQuickTransferDialog()
    })
    this.$eventHub.$on('open-login-dialog', obj => {
      if (obj != null) {
        this.isTransferWalletLoginDialog = obj.isTransfer
      }
      this.openLoginDialog()
    })
    this.$eventHub.$on('close-login-dialog', dialog => {
      this.closeLoginDialog()
    })
    this.checkReferCode()
  },
  beforeDestroy() {
    clearInterval(this.$options.validateMemberToken)
  },
  beforeCreate() {
    this.$OneSignal.getUserId(result => {
      if (result == null) {
        localStorage.removeItem('onesignal-notification-prompt')
        this.$OneSignal.showSlidedownPrompt()
      } else {
        this.oneSignal()
      }
    })
    this.$OneSignal.on('subscriptionChange', result => {
      if (result) {
        this.oneSignal()
        console.log('subscribe status ', result)
      }
    })
  },
  methods: {
    openCoinTipsDialog() {
      this.coinTipsDialog = true
    },
    closecoinTipsDialog() {
      this.coinTipsDialog = false
    },
    getListItemTo(gameType) {
      let routeName = ''
      switch (gameType.type) {
        case 'sb':
          routeName = ROUTE_NAME.SPORTSBOOK
          break
        case 'rng':
          routeName = ROUTE_NAME.SLOT
          break
        case 'fishing':
          routeName = ROUTE_NAME.FISHING
          break
        case 'special':
          routeName = ROUTE_NAME.SPECIAL
          break
        case 'ld':
          routeName = ROUTE_NAME.CASINO
          break
        case 'table':
          routeName = ROUTE_NAME.TABLE_GAMES
          break
        case 'lottery':
          routeName = ROUTE_NAME.LOTTERY
          break
        case 'crash':
          routeName = ROUTE_NAME.CRASH
          break
        case 'cricket':
          routeName = ROUTE_NAME.CRICKET
          break
        default:
          routeName = ROUTE_NAME.HOME
          break
      }
      if (this.$route.params.lang) {
        // If it has a value, include it in the route
        return { name: routeName, params: { lang: this.$route.params.lang } }
      } else {
        // If not, just return the route without params
        return { name: routeName }
      }
    },
    showIntercomLC() {
      Intercom('show')
    },
    AdElementInjectPush(pAe_parms_kv) {
      window.ae_parms_kv = pAe_parms_kv
      const el_adv_id = '71880509ee082f8f44596eedf687aac0'
      const scr = document.createElement('script')
      const host = '//d313lzv9559yp9.cloudfront.net'
      scr.type = 'text/javascript'
      scr.async = true
      scr.src = `${host}/${el_adv_id}.js`
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(scr, s)
    },
    AdElementLoginPixel() {
      var ae_parms_kv = {
        event_type: 'login_event', // Event type
        country_code: 'BD', // Country code
        lang_code: uiHelper.getLanguage().toUpperCase(), // Language code
        user_id: localStorage.getItem(SESSION.USERNAME), // Unique user identifier
        depth: 7
      }
      this.AdElementInjectPush(ae_parms_kv)
    },
    AdElementAfterRegistered(memberCode) {
      var ae_parms_kv = {
        event_type: 'register_event', // Event type
        country_code: 'BD', // Country code
        lang_code: uiHelper.getLanguage().toUpperCase(), // Language code
        register_id: memberCode, // Unique registration identifier
        depth: 5
      }
      this.AdElementInjectPush(ae_parms_kv)
    },
    clearAFCookiesByDomains() {
      let currentDomain = window.location.host.replace(/^www\./, '')
      let domainsToClear = ['krikya.app', 'krikya.pro']
      if (domainsToClear.indexOf(currentDomain) != -1) {
        uiHelper.removeCookie(SESSION.AFF_CODE)
        uiHelper.removeCookie(SESSION.STAG)
        uiHelper.removeCookie(SESSION.TRACKING_LINK)
      }
    },
    checkReferCode() {
      // this.clearAFCookiesByDomains();
      let af = this.$route.query.af
      let r = this.$route.query.refer_code
      let u_s = this.$route.query.utm_source
      let u_m = this.$route.query.utm_medium
      let u_c = this.$route.query.utm_campaign
      let s2 = this.$route.query.s2
      let pid = this.$route.query.sub1
      let refid = this.$route.query.ref_id
      let stag = this.$route.query.stag
      let tracking_link = this.$route.query.tracking_link

      try {
        let old_af = uiHelper.getCookie(SESSION.AFF_CODE)
        if (typeof old_af != 'undefined' && old_af != '' && old_af != null) {
          if (old_af !== af) {
            //if old af not match clear all pixel info.
            uiHelper.removeCookie(SESSION.PID)
            uiHelper.removeCookie(SESSION.CLICKID)
            uiHelper.removeCookie(SESSION.UTM_SOURCE)
            uiHelper.removeCookie(SESSION.UTM_MEDIUM)
            uiHelper.removeCookie(SESSION.UTM_CAMPAIGN)
            uiHelper.removeCookie(SESSION.UTM_S2)
          }
        }
      } catch (err) {
        console.log(err)
      }

      var domain = window.location.hostname
      var wwwDomain = domain.replace(/^www\./, '') // Remove "www." if it exists

      // Create a dictionary to map domains to expiration days
      var expirationDaysByDomain = {
        //first array = third party aff / second = agent code
        'krikya.com': [30, 30],
        'krikya.app': [30, 30],
        'krikya88.com': [30, 365],
        'krikya11.com': [30, 30],
        'krikya.pro': [30, 30],
        'krikya.club': [30, 30],
        'krikya.co': [30, 30],
        'krikya.group': [30, 30],
        'krikya22.com': [30, 30],
        'krikya44.com': [30, 30],
        'krikya55.com': [30, 30],
        'krikya66.com': [30, 30],
        'krikya77.com': [30, 365]
        // Add more domains and expiration days as needed
      }

      // Set the expiration day based on the domain
      var expirationDaysArray = expirationDaysByDomain[wwwDomain]

      // Default expiration (e.g., 1 day) if the domain is not in the dictionary or the array is not valid
      var expirationDaysThirdParty = 1
      var expirationDaysAgent = 365

      if (expirationDaysArray && expirationDaysArray.length === 2) {
        // Your logic to choose between short and long expiration here
        // For example, you can check some condition to decide:
        expirationDaysThirdParty = expirationDaysArray[0] // Use short expiration
        expirationDaysAgent = expirationDaysArray[1]
      }

      if (typeof stag != 'undefined' && stag != '' && stag != null) {
        uiHelper.setCookie(SESSION.STAG, stag, expirationDaysThirdParty)
      }
      if (typeof tracking_link != 'undefined' && tracking_link != '' && tracking_link != null) {
        uiHelper.setCookie(SESSION.TRACKING_LINK, tracking_link, expirationDaysThirdParty)
      }
      //PID at here set to utm_source
      if (typeof pid != 'undefined' && pid != '' && pid != null) {
        uiHelper.setCookie(SESSION.PID, pid, expirationDaysThirdParty)
      }
      //REFID at here set to utm_s2
      if (typeof refid != 'undefined' && refid != '' && refid != null) {
        uiHelper.setCookie(SESSION.CLICKID, refid, expirationDaysThirdParty)
      }
      if (typeof u_s != 'undefined' && u_s != '' && u_s != null) {
        uiHelper.setCookie(SESSION.UTM_SOURCE, u_s, expirationDaysThirdParty)
      }
      if (typeof u_m != 'undefined' && u_m != '' && u_m != null) {
        uiHelper.setCookie(SESSION.UTM_MEDIUM, u_m, expirationDaysThirdParty)
      }
      if (typeof u_c != 'undefined' && u_c != '' && u_c != null) {
        uiHelper.setCookie(SESSION.UTM_CAMPAIGN, u_c, expirationDaysThirdParty)
      }
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, expirationDaysThirdParty)
      }
      if (typeof af != 'undefined' && af != '' && af != null) {
        uiHelper.setCookie(SESSION.AFF_CODE, af, expirationDaysAgent)
        this.openRegisterDialog()
      } else {
        if (typeof r != 'undefined' && r != '' && r != null) {
          uiHelper.setCookie(SESSION.REFER_CODE, r, expirationDaysAgent)
          this.openRegisterDialog()
        }
      }
    },
    async oneSignal() {
      let data = {
        player_ids: await this.$OneSignal.getUserId(),
        is_subscribe: await this.$OneSignal.getSubscription(),
        permission_status: await this.$OneSignal.getNotificationPermission()
      }
      await this.$store.dispatch(`${ONE_SIGNAL_PLAYER_IDS}`, { data })
    },
    initializePageLanguage() {
      let language = uiHelper.getCookie('language')
      if (language == null) {
        language = SHARED.DEFAULT_LANGUAGE
      }
      locale.setCurrentLanguage(language)
      uiHelper.setCookie('language', language)
    },
    checkHasSsoToken() {
      let s2 = this.$route.query.s2
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      if (typeof this.$route.query.sso != 'undefined' && this.$route.query.sso != null && this.$route.query.sso != '') {
        localStorage.setItem(SESSION.TOKEN, this.$route.query.sso)
        this.$router.push({
          name: ROUTE_NAME.FUNDS
        })
      }
    },
    metaScript() {
      uiHelper.setSchema(this.currentLanguage)
    },
    initShowDownloadAPK() {
      this.showDownloadAPK = true
    },
    hideDownloadBar() {
      this.showDownloadAPK = false
    },
    refreshCurrentState() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
      this.$forceUpdate()
    },
    downloadAPK() {
      uiHelper.downloadAPK()
    },
    isAndroid() {
      try {
        let isAndroid = AndroidFunction.isMobile() ?? false
        uiHelper.setLocalStorage(CACHE_KEY.IS_ANDROID, isAndroid, 60)
      } catch (e) {
        uiHelper.setLocalStorage(CACHE_KEY.IS_ANDROID, false, 60)
      }
    },
    timeInterval() {
      let gmt = moment
        .parseZone()
        .toString()
        .slice(-8)
      gmt = gmt.substring(0, 4) + gmt.substring(5, 6) + ':' + gmt.substring(6, 8)
      setInterval(() => {
        this.time = `(${gmt}) ${moment()
          .utcOffset(0, true)
          .format('HH:mm:ss')}`
      }, 1000)
    },
    async getPopupAnnouncement() {
      let obj = {
        site: uiHelper.getPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${POPUP_LIST}`, {
        obj
      })
    },
    async getLoginPopupAnnouncement() {
      let obj = {
        site: uiHelper.getLoginPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${LOGIN_POPUP_LIST}`, {
        obj
      })
    },
    openInstantLoginPopup() {
      let dayClose = uiHelper.getCookie('popupLogin')
      if (dayClose == null) {
        this.instantLoginDialogShow = true
      }
    },
    closeInstantLoginPopup() {
      let dayClose = uiHelper.getCookie('popupLogin')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popupLogin', 'yes', 5)
      this.instantLoginDialogShow = false
    },
    openInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        this.instantDialogShow = true
      }
    },
    closeInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popup', 'yes', 0.5)
      this.instantDialogShow = false
    },
    getInboxMsg() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${INBOX_GET_MSG}`)
      }
    },

    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, {
        filter
      })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, {
        filter
      })
    },
    checkShouldHideAngpao() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_ANG_PAO) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_ANG_PAO) == 1) {
        this.showAngpao = false
      } else {
        this.showAngpao = true
      }
    },
    dontShowMeAgain() {
      this.showAngpao = false
      uiHelper.setCookie(SESSION.HIDE_ANG_PAO, 1, 0.5)
    },
    navigateToAngpao() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'angpao'
        }
      })
    },
    setGoogleAnalyticUserId() {
      if (this.memberInfo.memberCode != '') this.$ga.set('userId', this.memberInfo.memberCode)
    },
    openLiveChat() {
      try {
        this.$ga.event('Live Chat', `Open`, `Open Live Chat`)
      } catch (err) {
        console.log(err)
      }
      window.open('/livechat', 'Live Chat', 'width=350,height=600')
    },
    goToTransferPage() {
      this.$router.push({
        name: ROUTE_NAME.TRANSFER
      })
    },
    goToWithdrawalPage() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    },
    goToDepositPage() {
      // if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
      //   let routeData = this.$router.resolve({
      //     name: ROUTE_NAME.DEPOSIT
      //   })
      //   window.open(routeData.href)
      // } else {
      if (this.$route.params.lang) {
        this.$router.push({
          name: ROUTE_NAME.FUNDS,
          params: {
            lang: this.$route.params.lang,
            tab: 'deposit'
          }
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.FUNDS,
          params: {
            tab: 'deposit'
          }
        })
      }
      // this.$router.push({
      //   name: ROUTE_NAME.FUNDS
      //   , params:{lang: uiHelper.getRegionLocale()}
      // })
      //}
    },

    goToProfilePage() {
      // this.$router.push({
      //     name: ROUTE_NAME.PERSONAL,
      // })
      this.showProfileSheet = true
    },
    goToInboxPage() {
      this.$router.push({
        name: ROUTE_NAME.INBOX,
        params: {
          lang: this.$route.params.lang
        }
      })
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY))) - new Date() < 60000) {
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(MEMBER_LOGOUT, {
            logoutObj,
            sessionTimedOut
          })
        } else {
          this.$store.dispatch(MEMBER_REFRESH_TOKEN)
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
          requiredLoading
        })
      }
    },
    memberContactVerification(memberCode, otp, type, contact) {
      let verificationObj = {
        memberCode: memberCode,
        otp: otp,
        type: type,
        contact: contact
      }
      this.$store.dispatch(MEMBER_CONTACT_VERIFICATION, {
        verificationObj
      })
    },
    logout() {
      const logoutObj = {
        domain: uiHelper.getHostname(),
        platform: uiHelper.getPlatform()
      }
      let sessionTimedOut = false
      this.$store.dispatch(MEMBER_LOGOUT, {
        logoutObj,
        sessionTimedOut
      })
    },
    getMemberWallet() {
      const lastDispatchTime = sessionStorage.getItem('lastDispatchTime')

      if (!lastDispatchTime || Date.now() - parseInt(lastDispatchTime) > 15 * 1000) {
        this.notLoading = false
        sessionStorage.setItem('lastDispatchTime', Date.now()) // Update last dispatch time in sessionStorage
        this.$store.dispatch(MEMBER_WALLET)
      }

      this.maskBalance()
    },
    maskBalance() {
      this.showBalance = !this.showBalance
    },
    getMemberVipProgress() {
      this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
    },
    /* DIALOG */
    closeRegisterSuccessDialog() {
      this.registerSuccessDialogShow = false
    },
    closeQuickTransferDialog() {
      this.quickTransferDialogShow = false
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: this.ptGameInfo.provider,
          type: this.ptGameInfo.type
        },
        query: {
          id: this.ptGameInfo.id,
          code: this.ptGameInfo.code,
          demo: 0
        }
      })
      try {
        this.$ga.event('Game', `${0 ? 'Demo' : 'Real'} Play`, `${this.ptGameInfo.provider.toUpperCase()} - Slot - ${this.ptGameInfo.locale.find(x => x.language == 'en').name}`, this.ptGameInfo.id)
      } catch (err) {
        console.log(err)
      }
      uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
    },
    openRegister() {
      // this.registerDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
      this.$router.push({ name: ROUTE_NAME.REGISTER })
    },
    // openRegisterDialog() {
    //   // this.registerDialogShow = true
    //   this.closeWaitRegisterDialog();
    //   this.closeLoginDialog();
    //   this.closeForgotPasswordDialog();
    //   this.$router.push({ name: ROUTE_NAME.REGISTER });
    // },
    openRegisterDialog() {
      this.registerDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
      try {
        srtmCommands.push({
          event: 'track.user.registration',
          payload: {
            action: 'start'
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    closeRegisterDialog(actionFromRegisterDialog = true) {
      this.registerDialogShow = false
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      // if (this.firstTimeRegister && actionFromRegisterDialog) {
      //   this.openWaitRegisterDialog()
      //   this.firstTimeRegister = false
      // }
    },
    // openLoginDialog() {
    //   this.loginDialogShow = true
    //   this.closeWaitRegisterDialog()
    //   this.closeRegisterDialog(false)
    // },
    closeLoginDialog() {
      if (this.isTransferWalletLoginDialog) {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: 'all'
          }
        })
      }
      this.loginDialogShow = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true
      this.closeLoginDialog()
    },
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false
    },
    openResetPasswordDialog(memberCode, contact) {
      this.resetPasswordObj.memberCode = memberCode
      this.resetPasswordObj.contact = contact
      this.closeForgotPasswordDialog()
      this.resetPasswordDialogShow = true
    },
    closeResetPasswordDialog() {
      this.resetPasswordObj.memberCode = ''
      this.resetPasswordObj.contact = ''
      this.resetPasswordDialogShow = false
    },
    openLanguageDialog() {
      this.languageDialogShow = true
    },
    closeLanguageDialog() {
      this.languageDialogShow = false
    },
    openLoginDialog() {
      this.loginDialogShow = true
      this.closeForgotPasswordDialog()
      this.closeWaitRegisterDialog()
      this.closeRegisterDialog(false)
    },
    openQuickTransfer(gameInfo) {
      this.ptGameInfo = gameInfo
      this.quickTransferDialogShow = true
      this.transferToProvider = gameInfo.provider == 'pt' ? 'PT' : 'MAIN'
      this.transferMessage = locale.getMessage(`label.ptQuickTransfer`, [this.transferToProvider])
    },
    openProfileSheet() {
      this.showProfileSheet = true
    },
    closeProfileSheet() {
      this.showProfileSheet = false
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closeTimeOutDialog() {
      localStorage.removeItem(SESSION.TOKEN)
      this.closePageDialog()
      this.refreshCurrentState()
    },
    closePageDialog() {
      this.showProfileSheet = false
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    openWaitRegisterDialog() {
      this.waitRegisterDialogShow = true
    },
    closeWaitRegisterDialog() {
      this.waitRegisterDialogShow = false
    },
    /* RESPONSE COMPLETE DIALOG */
    loginResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.loginErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
      } else {
        this.closeLoginDialog()
        this.currentCurrency = uiHelper.getCurrency()
        this.closeRegisterDialog(false)

        //sportradar login complete
        let memberCode = this.$store.state.member.info.memberCode
        try {
          srtmCommands.push({
            event: 'track.user.login',
            payload: {
              action: 'complete',
              userId: memberCode
            }
          })
          console.log('done sradar login')
        } catch (err) {
          console.log(err)
        }
      }
    },
    logoutResponseCompleteDialog(response) {
      if (response.isSessionTimedOut) {
        this.$router.push({
          name: ROUTE_NAME.HOME
        })
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closeTimeOutDialog
        })
        this.openPageDialog(dialog)
      }
    },
    forgotPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.forgotPasswordSendText`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
        this.closeForgotPasswordDialog()
      }
      this.openPageDialog(dialog)
    },
    updateForgotPasswordCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.resetPassword`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    resetPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    closeResetPasswordSuccessDialog() {
      let self = this
      this.closePageDialog()
      setTimeout(function() {
        window.location.reload()
      }, 1)
    },
    registerResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
        this.openPageDialog(dialog)
        this.registerErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
      } else {
        //sportradar register complete
        try {
          srtmCommands.push({
            event: 'track.user.registration',
            payload: {
              action: 'complete',
              userId: this.$store.state.member.registerResponse.membercode
            }
          })
          console.log('done reg sradar!')
        } catch (err) {
          console.log(err)
        }

        this.registerSuccessDialogShow = true
        let vm = this
        setTimeout(function() {
          this.registerSuccessDialogShow = false
          vm.$router.push({
            name: ROUTE_NAME.FUNDS
          })
        }, 5000)
      }
    },
    verifyContactResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.contactVerificationComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    watchVideo() {
      window.open('https://krikyabangladesh.wistia.com/medias/ox5i927jcx', '_blank');
    }
  }
}
</script>

<style lang="scss">
.side-navigation-text {
  padding-left: 28px;
  color: var(--v-secondary-base);
  font-size: large;
}
.padding-l {
  color: #ffffff;
  font-size: 12px;
}

.padding-left {
  padding-left: 60%;
}

.breakWord-onspace {
  white-space: normal;
}

.vip-badge-mobile {
  .v-badge {
    margin-top: 11px;
  }
}

.v-btn--is-elevated {
  box-shadow: 0px 0px !important;
}

.coin_box_gif {
  position: fixed;
  bottom: 32%;
  right: 10px;
  z-index: 200;
  cursor: pointer;
}

.grab_coin_icon {
  animation: swing 1.2s;
  animation-iteration-count: 3;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

// .v-badge__badge {
//   bottom: 14px !important;
// }

.language_desktop {
  position: absolute;
  top: 70px;
  right: 3.5%;
}

.icon_middle_nav {
  width: 30px;
  stroke: white;
  fill: white;
}

.icon_btm_nav {
  width: 24px;
  height: 24px;
  margin-top: 8px;
  //stroke: #fff;
  fill: var(--v-text_color-base);

  &.chat {
    stroke: white;
    fill: white;
    width: 23px;
    margin-bottom: -8px;
  }

  &.depo_icon_btm_nav {
    height: 40px;
    width: 42px;
  }
}

.icon_normal {
  width: 25px;
  fill: white;
  color: black;
  stroke: #e4282f;
}

.icon_large {
  width: 35px;
  fill: white;
  color: black;
  margin-top: -5px;
}

.gold-gradient {
  background: transparent linear-gradient(180deg, #ffa15c 0%, #e2d200 100%) 0% 0% no-repeat padding-box;
  font-weight: bold;
}

.btn_img_lc {
  width: 30px;
  padding-bottom: 0;
  padding-left: 5px;
  margin-left: 10px;
}

.gold-color {
  background-color: #b87953 !important;
}

.yellow-color {
  background-color: #fddd04 !important;
}

.v-btn.theme-button.liveChatButton {
  background: transparent linear-gradient(90deg, #fa786a 0%, #c83563 50%, #86286d 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
}

.mobile-member-info-box {
  padding: 5px 15px;
  background: #121734;
  box-shadow: 0px 3px 6px #00000042;
  border-radius: 8px;

  .mobile-member-info-box-text {
    font-size: 15px;
  }

  .mobile-member-info-box-text-icon {
    font-size: 12px;
  }

  .mobile-member-info-box-balance {
    font-size: 15px;
    font-weight: bold;
  }

  .mobile-member-info-box-icon {
    height: auto !important;

    .v-icon {
      font-size: 28px;
    }
  }
}

.liveChatButton {
  width: 200px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  min-height: auto !important;
  position: fixed;
  height: auto;
  top: 30%;
  right: -80px;
  transform: rotate(-90deg);

  span {
    font-size: 24px;
    font-weight: normal;
  }
}

.header-row {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
}

button {
  &.round {
    border-radius: 50% !important;
  }

  &.buttonWhite {
    box-shadow: none;
  }

  span {
    letter-spacing: 0;
  }
}

.v-btn.primary-button {
  border-radius: 10px;
  color: black;
  text-transform: capitalize; // border-radius: 25px;
  width: 100%;
  font-size: 16px;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.brand-logo {
  position: absolute;
  left: 3%;
  transform: translateX(-3%);
  z-index: 1;
  top: -1%;
  width: 300px;
  margin-top: 20px;
}

.mobile-brand-logo {
  width: 250px;
}

.img_flag_select {
  width: 23px;
  height: 17px;
  margin-left: -10px;
  margin-right: 4px;
}

.member-menu-divider {
  border-color: white !important;
}

.div_header {
  background-color: #2d3487;
  padding-top: 20px;
}

.splitter_header {
  padding: 0;
  background-color: #3a3eb1;
  margin: 0 auto;
}

.header-icon-button-link {
  height: 40px !important;
  min-width: auto !important;
  width: 40px !important;
  margin-left: 8px;
}

.header-icon-button-more {
  height: 40px !important;
}

.status-bar {
  position: fixed;
  z-index: 5;
  height: 30px;
  width: 100%;
  background-color: var(--v-base-base);
  font-size: 14px;

  .status-bar-wrapper {
    width: 100%;
    max-width: 1240px;
    margin: auto;
  }

  .status-bar-language {
    .v-btn {
      background: rgba(255, 255, 255, 0.3);
      height: auto;
    }

    .v-btn:hover {
      background-color: rgba(13, 84, 62, 1);
    }

    .v-btn__content {
      text-transform: capitalize;
    }
  }

  .status-bar-icon {
    display: flex;
    position: relative;
    //color: #d5d5d5;
    color: var(--v-text_color-darken2);
    padding: 0px 8px;
    cursor: pointer;

    i {
      padding: 0px 4px 0px 0px;
      //color: #d5d5d5;
      color: var(--v-text_color-darken2);
    }

    .v-image {
      margin-right: 4px;
    }
  }

  .status-bar-icon:hover {
    //color: #ffffff;
    color: var(--v-text_color-lighten1);

    i {
      //color: #ffffff;
      color: var(--v-text_color-lighten1);
    }
  }

  .status-bar-icon:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    width: 1px;
    height: 60%;
    border-right: 1px solid var(--v-text-base);
    opacity: 0.8;
  }
}

.download-bar {
  position: fixed;
  z-index: 5;
  height: 92px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 12px;
  background-color: #f5f5f5;
  line-height: 1;

  >div {
    display: flex;
    flex-direction: column;
    width: calc(100% - 68px);
    margin-right: 12px;
  }

  label {
    font-size: 14px !important;
  }

  .download-bar-button {
    background: #45a67c !important;
    color: #fff;
  }

  .watch-video-bar-button {
    background: #F47922 !important;
    color: #fff;
  }
}

.download-button-container {
  display: flex;
  justify-content: space-between;

  button {
    width: 49% !important;
    height: 30px !important;
    font-size: 13px !important;
  }
}

.download-app-close {
  width: 38px;
  height: 28px;
  position: absolute !important;
  top: 0;
  right: 0;
  padding-left: 12px;
  padding-bottom: 4px;
  border-bottom-left-radius: 44px;
  color: #00464E !important;
  background-color: white;
}

.download-bar-height {
  margin-top: 92px;
}

.download-bar-height-body {
  margin-top: 134px;
}

.header-top {
  height: 80px;
  background: linear-gradient(to bottom, var(--v-primary-lighten2), var(--v-primary-base));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 5;

  .v-toolbar__content {
    max-width: 1240px;
    width: 100%;
    margin: auto;
    padding: 0px;
  }

  .theme--dark.v-btn.v-btn--icon {
    color: var(--v-text_reverse-base);
  }
}

.header-column {
  color: var(--v-text_reverse-base);

  .is-login-header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .btn-icon {
    margin: 0 2px;
    cursor: pointer;
  }

  .header-divider {
    width: 1px;
    height: 32px;
    margin: 0 4px;
    background-color: white;
  }

  .refresh-balance {
    transition: transform 0.5s;
  }

  .refresh-balance:hover {
    transform: rotate(215deg);
  }

  .invisible-button {
    height: 1.5rem;
    min-width: 120px;
    padding: 4px 12px;
    cursor: pointer;
    opacity: 1;

    .v-btn__content {
      opacity: 1 !important;
    }

    label {
      cursor: pointer;
    }
  }

  .invisible-button:hover {
    border: 1px solid var(--v-secondary-base);
    border-radius: 8px;
  }
}

.account-actions {
  display: flex;

  a {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
  }

  .flex-column {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    img {
      height: 35px;
    }
  }
}

.login-form .v-messages__message {
  font-size: 9px !important;
}

.header-bottom {
  min-height: 100px;
  color: #ffffff;
  background: transparent linear-gradient(180deg, #4145c4 0%, #121734 150%) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0;
  z-index: 15;
}

.side-navigation-drawer {
  $side-nav-p: 0px 20px;

  .list-item-container {
    padding: $side-nav-p;
  }

  .side-nav-logo {
    background-color: #303030;
    padding: 12px 15px;

    margin: 0px !important;
    border-radius: 0px !important;
  }

  .v-list {
    padding: 0px;
  }

  .v-list-item__title {
    color: var(--v-secondary-base);
    font-size: 16px;
    font-family: 'ReadexPro-Regular', sans-serif;
  }

  a {
    margin: 0px !important;

    &.v-list-item,
    &.v-list-item--link {
      border-radius: 0;
    }

    &.v-list-item::before,
    &.v-list-item--link::before {
      border-radius: 0;
    }

    &.v-list-item--active {
      //color: #ffffff !important;
      //background-color: var(--v-secondary-base);
    }
  }

  .mobile_list_menu {
    .v-list-item__icon {
      margin: 8px 0;
    }
  }

  .v-list-item__icon:first-child {
    margin-right: 12px;
  }

  .side-menu-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-menu-icon {
    width: 28px;
    height: 28px;
  }

  .vip-progress-bar {
    .v-progress-linear__background {
      background-color: #ebebeb !important;
      border-color: #ebebeb !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      border-radius: 10px;
    }

    label {
      color: #acacac;
    }
  }
}

.bottom-navigator {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background-color: #45a67c;
  font-size: 13px;

  .bottom-navigation-center-button {
    margin-top: -32px;
    min-width: 65px;
    height: 75px;
    padding: 12px;
    border-radius: 50px;
    background-image: linear-gradient(#70c59e, #368f6b);
    color: #fff;
    text-align: center;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 42%);
    cursor: pointer;
  }

  .bottom-navigator-before-login {
    height: 60px;
    background-color: #45a67c;

    .bottom-navigator-before-login-first {
      color: black;
      background-color: #ddd;
      border: 1px solid #ddd;
    }

    .bottom-navigator-before-login-second {
      color: white;
      background-color: var(--v-secondary-base);
    }

    .bottom-navigator-before-login-third {
      color: white;
      border: 1px solid var(--v-primary-base);
    }
  }

  .theme--light.v-btn.v-btn--has-bg {
    background-color: transparent;
  }

  a {
    //height: 50px !important;
    letter-spacing: 1px;
    padding: 0 !important;
    display: block;
    border-color: var(--v-base_3-base);
    text-decoration: none;

    &::before {
      opacity: 0 !important;
    }

    // &.link-active {
    //   border-color: #8C8CDB;
    //   .icon_btm_nav {
    //     fill: #8C8CDB;
    //     stroke: #8C8CDB;
    //     ;
    //   }
    //   .v-icon {
    //     color: #8C8CDB;
    //   }
    //   p {
    //     color: #8C8CDB;
    //   }
    // }
    p {
      margin: -2px 0 0 0;
      text-transform: capitalize;
      color: white;
    }
  }
}

.mobile-header-detail {
  // margin-top: 36px !important;
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 12px;
  min-height: 46px;

  .header-wallet {
    font-size: 14px;
    padding: 15px 35px 15px 15px;
  }

  .header-wallet-icon {
    margin-left: -25px;
  }
}

.mobile-header-rightBtns {
  justify-content: flex-end;
}

@media (max-width: 1499px) {
  .div_header {
    padding-top: 0;
  }

  .language_desktop {
    top: 60px;
    right: 4%;
  }
}

@media (max-width: 1263px) {
  .language_desktop {
    right: 6%;
  }

  .header-icon-button-link {
    height: 35px !important;
    width: 35px !important;
    margin-left: 4px;
  }

  .header-icon-button-more {
    height: 35px !important;
    margin-left: 4px;
    min-width: auto !important;
    width: 30px !important;

    .v-icon {
      height: 15px !important;
      font-size: 28px !important;
    }
  }

  .header-text {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .header-text-statis-word {
    display: none;
  }
}

@media (max-width: 510px) {
  .side-navigation-drawer .v-list-item__title {
    font-size: 16px;
  }
  .side-navigation-drawer .list-item-container {
    padding: 8px 10px;
  }
}

@media (max-width: 450px) {
  .customwh {
    width: 100px !important;
    height: 25px !important;
  }
  .side-navigation-drawer {
    .side-nav-logo {
      padding-top: 18px;
      padding-right: 7px;
    }
  }
}

//
//@media(max-width: 385px){
//  .padding-l {
//    font-size: 6px;
//  }
//}
//@media(max-width: 355px){
//  .padding-l {
//    font-size: 4px;
//  }
//}
@media (max-width: 599px) {
  .header-top {
    height: 60px;
    // background: var(--v-primary-base) !important;

    .v-toolbar__content {
      padding: 4px 16px;
    }

    .toolbar-icon {
      color: var(--v-text_reverse-base);
    }
  }

  .v-btn.primary-button {
    font-size: 12px;
    width: 100%;
  }

  .language_desktop {
    position: absolute;
    top: 50px;
    right: 13%;
  }

  .liveChatButton {
    display: none;
  }

  .header {
    height: 50px;
    background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
    padding: 0 15px;

    .mobile-brand-logo {
      width: auto !important;
      height: 25px !important;
      margin-top: 5px;
    }

    .header-mobile-livechat {
      position: absolute;
      top: 0;
      right: 15px;
      height: 100%;

      .v-icon {
        font-size: 25px;
        padding-right: 5px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }

  .v-btn.v-size--default {
    font-size: 14px;
  }
  .v-btn.v-btn--icon.v-size--default {
    width: 40px !important;
  }
}

@media (max-width: 599px) {
  .header {
    padding: 0 15px;

    .header-mobile-livechat {
      .v-icon {
        font-size: 25px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }

  .mobile-brand-logo {
    margin-top: -10px;
    margin-left: -10px;
    width: 200px;
  }

  .profile-alert-success {
    font-size: 14px;
  }

  .profile-alert-failed {
    font-size: 14px;
  }

  .bottom-navigator {
    background-color: #45a67c;

    .v-btn.v-btn--has-bg {
      background: transparent;

      .v-btn__content {
        font-size: 12px;
        text-transform: capitalize;

        p {
          margin: 0;
        }
      }
    }
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
