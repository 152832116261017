<template>
  <v-col no-gutters class="language_panel">
    <v-row no-gutters justify="space-between" align-content="center" class="d-flex flex-nowrap"
           v-for="(lang, index) in filterLanguages" :key="lang.currency">
      <div no-gutters class="align-self-center flex-shrink-1">
        <v-avatar class="language-button" :size="$vuetify.breakpoint.xsOnly ? '35': '48'">
          <img width="60" height="60" :src="`/static/image/country/${lang.img}.svg`" alt="krikya cricket exchange" />
        </v-avatar>
      </div>
      <div class="align-self-center flex-shrink-1 mx-2">
        <label class="">{{ lang.currencyCd }} {{ lang.currency }}</label>
      </div>
      <v-row no-gutters justify="center" class="align-self-center flex-grow-1 d-flex flex-nowrap">
        <!-- <v-col v-model="selectedLanguage" class="pl-2" v-for="l in lang.language" :key="l.cd">
            <v-btn :value="lang.currency + l.cd" class="font-weight-bold text-capitalize flex-grow-1" outlined @click="changeLanguage(l.cd, lang.currency)">{{l.title}}</v-btn>
        </v-col> -->

        <v-btn-toggle v-model="selectedLanguage" :group="true" :borderless="true" dense class="pl-2 flex-grow-1"
                      v-for="l in lang.language" :key="l.cd">
          <a class="localized" :href="localizedLink(l.cd)">
          <v-btn :value="lang.currency + l.cd" class="font-weight-bold text-capitalize full-width" outlined
                 @click="changeLanguage(l.cd, lang.currency)">{{ l.title }}
          </v-btn>
          </a>
        </v-btn-toggle>
      </v-row>
    </v-row>

  </v-col>
</template>

<script>
import stingFormat from "string-format";
import {
  formValidator,
  uiHelper,
  errorCodeHelper
} from "@/util";
import {
  SHARED
} from "@/constants/constants";
import {
  MEMBER_LOGIN
} from "@/store/member.module";

export default {
  name: "appLanguage",
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {
      }
    }
  },
  data: () => ({
    languageList: [{
      currency: "BDT",
      currencyCd: "৳",
      img: "BDT",
      language: [{
        title: "English",
        cd: "en"
      },
        {
          title: "Bengali",
          cd: "bd"
        }
      ]
    }
      // {
      //     currency: 'INR',
      //     currencyCd: '₹',
      //     img: "INR",
      //     language: [
      //         {
      //             title: 'English',
      //             cd: 'en'
      //         },
      //         {
      //             title: 'Tamil',
      //             cd: 'ta'
      //         }
      //     ]
      // }
    ],
    selectedLanguage: "",
    currentLanguage: uiHelper.getLanguage()
  }),
  computed: {
    modifiedLanguageCodes() {
      // Map language codes to their modifications
      const modifications = {
        'bd': 'bn', // Add other mappings if needed
      };
      return modifications;
    },
    localizedLink() {
      return (languageCode) => {
        const modifiedCode = this.modifiedLanguageCodes[languageCode] || languageCode;
        return `${modifiedCode}-BD${this.getLocalizedPath()}`;
      };
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    },
    currentCurrency() {
      return uiHelper.getCurrency();
    },
    filterLanguages() {
      if (this.isLoggedIn) {
        let list = this.languageList.filter(l => l.currency == this.currentCurrency);
        if (list && list.length > 0)
          return list;
        else
          return this.languageList;
      } else {
        return this.languageList;
      }
    }
  },
  created() {
    this.selectedLanguage = this.currentCurrency + this.currentLanguage;
  },
  methods: {
    getLocalizedPath() {
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      return cleanPath;
    },
    getCountryCode(code) {
      let REG = /^[a-z]{2,}(?:-[A-Z][a-z]*)*-([A-Z]{2})$/;
      var match = code.match(REG);
      if (!match) return "";
      // console.log('isValidCountryCode:', match);
      return match[1];

    },
    changeLanguage(languageCd, currCd) {
      this.$store.dispatch(`${SHARED.LOADING}`);
      const langRegion = (languageCd == 'bd' ? 'bn' : languageCd) + '-' + currCd.substring(0, 2);
      uiHelper.removeCookie('regionLocale');
      uiHelper.removeCookie('language');
      uiHelper.removeCookie('changeLanguage');
      uiHelper.setCookie('regionLocale', langRegion);
      uiHelper.setCookie('language', languageCd);
      uiHelper.setCookie('changeLanguage', true);
      uiHelper.setCurrency(currCd);
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      let extractPath = window.location.pathname.split('/');
      const results = extractPath.filter(element => {
        return element !== '';
      });
      if (results.length > 0) {
        let region = results[0];
        //test is valid locale
        let isValidCountryCode = this.getCountryCode(region);
        if (isValidCountryCode) {
          const newArr = [uiHelper.getRegionLocale(), ...results.slice(1)];
          const joinUrl = newArr.join('/');
          window.location.replace(window.location.origin + '/' + joinUrl);
        }else{
          this.$router.push({ params: { lang: uiHelper.getRegionLocale() } });
          window.location.reload();
        }
      } else {
        this.$router.push({ params: { lang: uiHelper.getRegionLocale() } });
        window.location.reload();

      }
    }
  }
};
</script>

<style lang="scss">
.language_panel {
  color: #000000;
  background-color: #ffffff;
  padding: 12px 16px !important;

  .language-divider {
    border-color: var(--v-border-base);
  }

  .v-btn-toggle--group {
    .v-btn {
      color: #000000;
      border: 1px solid var(--v-description-base);

      &.v-btn--active {
        color: var(--v-primary-base);
        border: 1px solid var(--v-primary-base);
      }
    }

    .v-btn:hover {
      color: var(--v-primary-base);
      border: 1px solid var(--v-primary-base);
    }
  }

  .v-btn::before {
    background-color: unset;
  }
}

@media (max-width: 959px) {
  // .language_panel {
  //     color: unset;
  //     background-color: #3e3e3e;
  //     padding: unset;

  //     .v-btn-toggle--group {
  //         .v-btn {
  //             color: unset;
  //             border: 1px solid #ffffff;

  //             &.v-btn--active {
  //                 color: var(--v-secondary-base);
  //                 border-color: var(--v-secondary-base);
  //                 border: 1px solid var(--v-secondary-base);
  //             }
  //         }
  //     }
  // }
}

@media (max-width: 425px) {
  .language_panel {
    .v-btn {
      font-size: 12px !important;
      height: 35px !important;
    }
  }
}
</style>
